import objectPath from "object-path";

const initialState = {
  versionChecked: false,
  dataConfig: {},
  layoutLoader: { open: true, key: 1, duration: 1 },
  pathname: "",
  preferenceUpdate: 0,
  productCategories: [],
  menuProductCategories: [],
  preferences: {},

  menuConfig: {
    menuCurrentPage: "",
    menuPageConfigs: {
      intro: {
        page: "intro",
        title: "Bun venit la"
      },
      category: {
        page: "category",
        title: ""
      },
      product_category: {
        page: "product_category",
        categorySelected: {},
        title: ""
      },
    },
    cartProducts: [],
    cartProductsOpen: false,
    totalCartProducts: 0,
    totalCartProductsIndexing: {},
    bundleLines: []
  },
  htmlService: {
    productModalTransitionTime: 300,
    pageTransitionTime: 400
  }
};
const actionTypes = {
  RESET_CONFIG: "RESET_CONFIG",
  SET_LAYOUT_LOADER: "SET_LAYOUT_LOADER",
  UPDATE_PATHNAME: "UPDATE_PATHNAME",
  SET_VERSION_CHECKED: "SET_VERSION_CHECKED",
  SET_ALL_PREFERENCES: "SET_ALL_PREFERENCES",
  SET_PREFERENCE_UPDATE: "SET_PREFERENCE_UPDATE",
  SET_ALL_PRODUCT_CATEGORIES: "SET_ALL_PRODUCT_CATEGORIES",
  SET_MENU_PRODUCT_CATEGORIES: "SET_MENU_PRODUCT_CATEGORIES",
  SET_MENU_CURRENT_PAGE: "SET_MENU_CURRENT_PAGE", 
  SET_MENU_PAGE_CONFIGS: "SET_MENU_PAGE_CONFIGS",
  SET_CART_PRODUCTS_MODAL_OPEN: "SET_CART_PRODUCTS_MODAL_OPEN",
  SET_CART_PRODUCTS: "SET_CART_PRODUCTS",
  SET_TOTAL_PRICE_CART_PRODUCTS: "SET_TOTAL_PRICE_CART_PRODUCTS",
  SET_CART_PRODUCTS_INDEXING: "SET_CART_PRODUCTS_INDEXING",
  SET_CATEGORY_PREVIOUS_SCROLL_TOP: "SET_CATEGORY_PREVIOUS_SCROLL_TOP",
  SET_BUNDLE_LINES: "SET_BUNDLE_LINES",

};
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET_CONFIG: {
      return initialState;
    }
    case actionTypes.SET_LAYOUT_LOADER: {
      return {
        ...state,
        layoutLoader: {
          ...payload.layoutLoader
        },
      };
    }
    case actionTypes.UPDATE_PATHNAME: {
      return {
        ...state,
        pathname: payload.pathname,
      };
    }
    case actionTypes.SET_VERSION_CHECKED: {
      return {
        ...state,
        versionChecked: payload.versionChecked,
      };
    }
    case actionTypes.SET_ALL_PREFERENCES: {
      return {
        ...state,
        preferences: payload.preferences,
      };
    }
    case actionTypes.SET_PREFERENCE_UPDATE: {
      return {
        ...state,
        preferenceUpdate: payload.preferenceUpdate,
      };
    }
    case actionTypes.SET_ALL_PRODUCT_CATEGORIES: {
      return {
        ...state,
        productCategories: payload.productCategories,
      };
    }
    case actionTypes.SET_MENU_PRODUCT_CATEGORIES: {
      return {
        ...state,
        menuProductCategories: payload.menuProductCategories,
      };
    }

    case actionTypes.SET_MENU_CURRENT_PAGE: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          menuCurrentPage: payload.menuCurrentPage
        }
      };
    }
    case actionTypes.SET_MENU_PAGE_CONFIGS: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          menuPageConfigs: payload.menuPageConfigs
        }
      };
    }
    case actionTypes.SET_CART_PRODUCTS_MODAL_OPEN: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          cartProductsOpen: payload.cartProductsOpen,
          cartProductsOpenTransitionTime: payload.cartProductsOpenTransitionTime
        }
      };
    }
    case actionTypes.SET_CART_PRODUCTS: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          cartProducts: payload.cartProducts
        }
      };
    }
    case actionTypes.SET_BUNDLE_LINES: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          bundleLines: payload.bundleLines
        }
      };
    }
    case actionTypes.SET_TOTAL_PRICE_CART_PRODUCTS: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          totalCartProducts: payload.totalCartProducts
        }
      }
    }
    case actionTypes.SET_CART_PRODUCTS_INDEXING: {
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          cartProductsIndexing: payload.cartProductsIndexing
        }
      }
    }

    //html service
    case actionTypes.SET_CATEGORY_PREVIOUS_SCROLL_TOP: {
      return {
        ...state,
        htmlService: {
          ...state.htmlService,
          categoryPreviousScrollTop: payload.categoryPreviousScrollTop
        }
      }
    }
    
    default:
      return state;
  }
};
export const actions = {
  setLayoutLoader: (payload) => ({
    payload: {
      ...payload,
      layoutLoader: {
        ...(payload.layoutLoader ? {...payload.layoutLoader} : {}),
        key: Date.now(),
        duration: payload.layoutLoader && payload.layoutLoader.duration ? payload.layoutLoader.duration : 400 
      }
    },
    type: actionTypes.SET_LAYOUT_LOADER,
  }),
  setVersionChecked: (payload) => ({
    payload,
    type: actionTypes.SET_VERSION_CHECKED,
  }),
  updatePathname: (payload) => ({ payload, type: actionTypes.UPDATE_PATHNAME }),
  setAllPreferences: (payload) => ({
    payload,
    type: actionTypes.SET_ALL_PREFERENCES,
  }),
  setAllProductCategories: (payload) => ({
    payload,
    type: actionTypes.SET_ALL_PRODUCT_CATEGORIES,
  }),
  setMenuProductCategories: (payload) => ({
    payload,
    type: actionTypes.SET_MENU_PRODUCT_CATEGORIES,
  }),
  setPreferenceUpdate: (payload) => ({
    payload,
    type: actionTypes.SET_PREFERENCE_UPDATE,
  }),
  setBundleLines: (payload) => ({
    payload,
    type: actionTypes.SET_BUNDLE_LINES
  }),

  //menu navigation
  setMenuCurrentPage: (payload) => ({
    payload,
    type: actionTypes.SET_MENU_CURRENT_PAGE,
  }),
  setMenuPageConfigs: (payload) => ({
    payload,
    type: actionTypes.SET_MENU_PAGE_CONFIGS,
  }),
  setCartProductsOpen: (payload) => ({
    payload,
    type: actionTypes.SET_CART_PRODUCTS_MODAL_OPEN,
  }),
  setCartProducts: (payload) => ({
    payload,
    type: actionTypes.SET_CART_PRODUCTS,
  }),
  setCartProductsIndexing: (payload) => ({
    payload,
    type: actionTypes.SET_CART_PRODUCTS_INDEXING,
  }),
  setTotalPriceCartProducts: (payload) => ({
    payload,
    type: actionTypes.SET_TOTAL_PRICE_CART_PRODUCTS,
  }),
  
  //html service
  setCategoryPreviousScrollTop: (payload) => ({
    payload,
    type: actionTypes.SET_CATEGORY_PREVIOUS_SCROLL_TOP,
  }),
};
export const selectors = {
  getValue: (builder, path) => {
    return objectPath.get(builder, path);
  },
  getLanguage: (builder, type, properties) => {
    return null;
  },
  getMenuCurrentPageSlug: (pathname) => {
    const pathname_ = pathname || "";
    if(pathname_.indexOf("meniu/intro") > 0) {
      return "intro";
    }
    if(pathname_.indexOf("meniu/category") > 0) {
      return "category";
    }
    if(pathname_.indexOf("meniu/product_category") > 0) {
      return "product_category";
    }
    return "intro";
  }
};
