import * as helperFunctions from "utils/helper_functions";
import * as constants from "../../../utils/constants";

/**
 * @typedef {Object} IBlockedDateItem
 * @property {String} blocked_delivery
 * @property {String} blocked_reservation
 * @property {String} date_blocked
 * @property {String} hour_finish
 * @property {String} hour_start
 * @property {String} id_blocked
 * @property {String} id_user
 * @property {String} id_venue
 * @property {String} reason
 */
export default class HomeHelper {
  /**
   * - gather all the dates that have blocked intervals today
   * - them into generic properties ([{start, end}])
   *
   * @param {string} date_string - date by which the program blocked is filtered
   * @returns {Array([{start, end}])}
   */
  static computeBlockedProgram = (state) => (date_string) => {
    
    /** blocked intervals ignored if pickup is selected and the required preference is selected */
    const allowPickupDates =
      state.pickup_skip_blocked &&
      state.shipping_form.delivery_type === constants.delivery_ids_from_name.pickup;
    if(allowPickupDates) {
      return [];
    }

    let blocked_program = Array.isArray(state.blocked_program)
      ? state.blocked_program
      : [];

    return (
      blocked_program
        /**
         * filter intervals by date -> every has to be equal to date_string
         */
        .filter(({ date_blocked }) => {
          return (
            date_blocked &&
            helperFunctions.compare_timesets(
              `${date_blocked} 00:00:00`,
              `${date_string} 00:00:00`,
              true
            ) === 0
          );
        })
        .map(({ hour_start, hour_finish }) => {
          if (!hour_start || !hour_finish) {
            hour_start = "00:00:00";
            hour_finish = "23:59:59";
          }
          
          return {
            start: hour_start,
            end: hour_finish,
          };
        })
    );
  };

  /**
   *
   * @param {string} date_string  - short date - Ex. "2021/03/15"
   * @param {*} additional
   * @returns {Interval{star, end}[]} - a set of intervals that represents that are valid for a given date
   *                                  - this takes into account shipping method (pickup, delivery), county
   */
  static computeProgramIntervals = (state) => (date_string, additional) => {
    const date = new Date(date_string);
    const date_day = date.getDay();

    const shippingForm = additional.custom_shipping_form || state.shipping_form;
    const pickupSelected =
      shippingForm.delivery_type === constants.delivery_ids_from_name.pickup;

    const selectedCountyId = shippingForm.id_county;

    const program_delivery = Array.isArray(state.program_delivery)
      ? state.program_delivery
      : [];

    /**
     * NOTE:
     * (program_start, program_end, closed) - these are the values for simple delivery
     * (program_start_pickup, program_end_pickup, closed_pickup) - these are the values for pickup
     *
     * ACTION:
     * if pickup is selected -> 1. overwrite the simple delivery values with pickup (if these values are not null)
     */
    let program_delivery_prepared = [];
    if (pickupSelected) {
      program_delivery_prepared = program_delivery.map((programItem) => {
        const {
          program_start_pickup,
          program_end_pickup,
          closed_pickup,
        } = programItem;

        if ((program_start_pickup && program_end_pickup) || closed_pickup) {
          return {
            ...programItem,
            program_start: program_start_pickup,
            program_end: program_end_pickup,
            closed: closed_pickup,
          };
        }
        return programItem;
      });
    } else {
      program_delivery_prepared = program_delivery;
    }

    return (
      program_delivery_prepared
        /**
         * filter program by the day of the week of the target date_string
         * filter program that are closed for the day of the week
         */
        .filter(
          ({ program_start, program_end, day_of_the_week, closed }) =>
            !closed &&
            constants.db_to_datejs_ind[day_of_the_week] === date_day &&
            program_start !== program_end
        )
        /**
         * re-filter by method of shipping
         * re-filter by by county
         */
        .filter((programItem) => {
          if (pickupSelected) {
            return true;
          }
          if (!selectedCountyId || !programItem.id_county) {
            return true;
          }
          return programItem.id_county === selectedCountyId;
        })
        /**
         * compute the array to a generic structure (hour start, hour end)
         */
        .map(({ program_start, program_end }) => {
          return {
            start: program_start,
            end: program_end,
          };
        })
    );
  };

  /**
   *
   * @param {string} hourString
   * @param {Interval{star, end}[]} intervals
   * @returns {boolean}
   */
  static hourIsIncludedInterval = () => (hourString, intervals) => {
    return intervals.some(({ start, end }) => {
      return (
        helperFunctions.compare_timesets(hourString, start) >= 0 &&
        helperFunctions.compare_timesets(hourString, end) <= 0
      );
    });
  };

  /**
   * if date_string is today time for preparation must be validated
   * if date_string is today make sure hour is passed now
   * @param {string} hourString - hour to be validated
   * @param {string} date_string  - short date - Ex. "2021/03/15"
   * @returns
   */
  static dateIsNotTodayOrIsValidAsToday = (state) => (
    hourString,
    date_string
  ) => {
    const shortDateString = helperFunctions.date_to_string(new Date());
    const timesetToday = helperFunctions.date_to_time(new Date());

    if (shortDateString === date_string) {
      /**
       * time in seconds -> the order can NOT be shipped until now + this time
       */
      let timePreparation = 60 * 60;
      if (
        Number(state.time_before_preparation) ||
        state.time_before_preparation === 0
      ) {
        timePreparation = Number(state.time_before_preparation);
      }

      return (
        /**
         * 1. hour MUST BE passed now
         */
        helperFunctions.compare_timesets(hourString, timesetToday) >= 0 &&
        /**
         * 2. hour MUST pass the -> now + time_preparation
         */
        helperFunctions.date_difference(
          new Date(`${shortDateString} ${hourString}`),
          new Date(`${shortDateString} ${timesetToday}`)
        ) >= timePreparation
      );
    }

    return true;
  };

  /**
   * if the blocked doesn't have a interval (hour start - hour end) -> blocked all day
   * @param {IBlockedDateItem} blockedItem
   */
  static dateBlockAllDay = (blockedItem) => {
    return !blockedItem.hour_start || !blockedItem.hour_finish;
  };

  /**
   * if now is blocked by blocked_program -> asap should be forbiden
   */
  static asapDisabledFromBlockedIntervals = () => (blocked_program) => {
    if (Array.isArray(blocked_program)) {
      const shortDateTodayString = helperFunctions.date_to_string(new Date());
      const timePreparation = 60000 * 60;
      const now = new Date().getTime();

      for (let i = 0; i < blocked_program.length; i++) {
        /**
         * @type {IBlockedDateItem}
         */
        const item = blocked_program[i];

        /**
         * is today
         */
        if (
          helperFunctions.compareShortDateString(
            item.date_blocked,
            shortDateTodayString
          ) === 0
        ) {
          if (HomeHelper.dateBlockAllDay(item)) {
            //dev
            window.dev_disabled_from_blocked = {
              all_day: true,
              item,
            };

            return true;
          }

          const timeStart = new Date(
            `${item.date_blocked} ${item.hour_start}`
          ).getTime();
          const timeFinish = new Date(
            `${item.date_blocked} ${item.hour_finish}`
          ).getTime();

          /**
           * if now is blocked -> asap true
           */
          const nowBool = now >= timeStart && now < timeFinish;
          // if in 30 minutes blocked period begins
          const inTimePrepBool =
            now + timePreparation >= timeStart &&
            now + timePreparation < timeFinish;

          if (nowBool || inTimePrepBool) {
            //dev
            window.dev_disabled_from_blocked = {
              start_block: `${item.date_blocked} ${item.hour_start}`,
              end_block: `${item.date_blocked} ${item.hour_finish}`,
              timeStart,
              timeFinish,
              now,
              item,
              nowBool,
              inTimePrepBool,
            };
            return true;
          }
        }
      }
    }
    return false;
  };
}
