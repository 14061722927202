// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath, { set } from "object-path";

import * as helperFunctions from "utils/helper_functions";
import { actions } from "store/reducers/builderReducer";
import { cartProductsActions } from "utils/actionHelpers";
import { cx, css } from "emotion";
import MenuButton from "./MenuButton/MenuButton";
import MenuSelect from "./MenuSelect/MenuSelect";
import { menuOrderActions } from "../../../store/actions/menuOrderActions";
import { menuActions } from "./menuActions";

export default function MenuCartModal({ forceHide }) {
  const dispatch = useDispatch();
  const {
    cartProducts,
    bundleLines,
    cartProductsOpen,
    menuPageConfigs,
    productModalTransitionTime,
    totalCartProducts,
    id_venue,
    enable_digital_menu_order,
    tables,
  } = useSelector(({ builder: { menuConfig, htmlService, preferences } }) => ({
    cartProducts: objectPath.get(menuConfig, "cartProducts") || [],
    bundleLines: objectPath.get(menuConfig, "bundleLines"),
    cartProductsOpen: objectPath.get(menuConfig, "cartProductsOpen"),
    productModalTransitionTime: htmlService.productModalTransitionTime || 0,
    totalCartProducts: objectPath.get(menuConfig, "totalCartProducts"),
    id_venue: objectPath.get(preferences, "id_venue"),
    enable_digital_menu_order:
      objectPath.get(preferences, "enable_digital_menu_order") === "1",
    tables: objectPath.get(preferences, "tables") || [],
  }));
  const [selectedTableIndex, setSelectedTableIndex] = React.useState(-1);
  const showMenuCarModal = cartProductsOpen && !forceHide;
  const [displayNone, setDisplayNone] = React.useState(true);
  const [state, setState] = React.useState({ errors: {}, showErrors: false });
  const [containerStyle, setContainerStyle] = React.useState({});


  const refCart = React.useRef();

  function onClose() {
    menuActions(dispatch).closeCartModal();
  }
  function listen(event) {
    const hasClickedOutside = helperFunctions.listenOutsideClick(
      event,
      "#id-menu-cart-products",
      ".cart-products"
    );
    if (hasClickedOutside) {
      onClose();
    }
  }
  function setQuantity({ quantity, id_bundle }) {
    if (quantity === 0) {
      window.removeEventListener("click", listen);
      setTimeout(() => {
        cartProductsActions(dispatch, id_venue).setProductQuantity({
          cartProducts,
          id_bundle,
          quantity,
        });
        if (cartProductsOpen !== true || forceHide) {
          window.addEventListener("click", listen);
        }
      }, 100);
    } else {
      cartProductsActions(dispatch, id_venue).setProductQuantity({
        cartProducts,
        id_bundle,
        quantity,
      });
    }
  }
  function checkErrors() {
    return {
      cartProducts: cartProducts.length === 0,
      id_table: !Boolean(
        objectPath.get(tables, `${selectedTableIndex}.id_table`)
      ),
    };
  }
  function submitMenuOrder() {
    const errors = checkErrors();
    if (Object.entries(errors).some(([prop, val]) => val)) {
      setState({ showErrors: true, errors });
      return;
    }
    
    if (state.showErrors) {
      setState({ ...state, showErrors: false });
    }

    menuOrderActions(dispatch).submitMenuOrder(
      {
        id_venue,
        id_table: objectPath.get(tables, `${selectedTableIndex}.id_table`),
        id_venue_location: objectPath.get(
          tables,
          `${selectedTableIndex}.id_zone`
        ),
      },
      cartProducts
    );
  }

  React.useEffect(
    () => {
      if (cartProductsOpen !== true || forceHide) {
        setTimeout(() => {
          setDisplayNone(true);
        }, productModalTransitionTime);
        window.addEventListener("click", listen);
        setContainerStyle({
          maxHeight: window.innerHeight - 70
        });
      } else {
        setDisplayNone(false);

        window.removeEventListener("click", listen);
        // setTimeout(() => {
        //   if(document.do) {
        //     refCart.current.scrollTo({x: 0, y: 5000});
        //   }
        // }, 200);
      }
    },
    [cartProductsOpen, forceHide]
  );
  React.useEffect(
    () => {
      setSelectedTableIndex(-1);
    },
    [tables]
  );
  React.useEffect(
    () => {
      if (state.showErrors) {
        setState({ ...state, errors: checkErrors() });
      }
    },
    [selectedTableIndex, cartProducts, state.showErrors]
  );
  const totalText =
    totalCartProducts === 1 ? "1 leu" : `${totalCartProducts} lei`;

  const { errors, showErrors } = state || {};

  return displayNone ? (
    ""
  ) : (
    <div
      className={cx(
        `menu-modal-generic-container${
          showMenuCarModal
            ? " modal-open-animation-class"
            : " modal-closing-animation-class"
        }`,
        css`
          animation-duration: ${productModalTransitionTime / 1000}s;
        `
      )}
    >
      <div
        id="id-menu-cart-products"
        className={cx(
          `my-order${
            showMenuCarModal
              ? " modal-open-animation-class-fr-chid"
              : " modal-closing-animation-class-fr-chid"
          }`,
          css`
            animation-duration: ${productModalTransitionTime / 1000}s;
          `
        )}
        ref={refCart}
        style={{...containerStyle}}
      >
        <div className="my-order-inner">
          <div className="my-order-title jost">Comanda mea</div>

          <div className="my-order-close" onClick={onClose}>
            <i className="fa fa-times x-btn-selection" />
          </div>

          {bundleLines.map((bundle, index) => {
            const { id_bundle, target, assoc, totalLine } = bundle;

            return (
              <div
                key={`menu-product-cart-item-bundle-${id_bundle}-${index}`}
                className="my-order-product"
              >
                <div className="my-order-product-name jost">
                  <div> {target.product_name} </div>
                  <div className="assoc-product-menu">
                    {assoc.reduce(
                      (assocText, assocItem, indexItem) =>
                        `${assocText}${assocItem.product_name}${
                          indexItem < assoc.length - 1 ? ", " : ""
                        }`,
                      ""
                    )}
                  </div>
                </div>
                <div className="cart-rigth-meniu-part-item">
                  <div className="my-order-product-qty">
                    <div className="qty-inner">
                      <div className="minus-btn-qty inc-dec-btn-rel-cont-menu">
                        <div
                          className="btn-section-tap-mask"
                          onClick={(event) => {
                            const quantity = (Number(target.quantity) || 1) - 1;
                            setQuantity({
                              quantity,
                              id_bundle,
                            });
                          }}
                        />
                        <i className="fa fa-minus-square" />
                      </div>
                      <div className="actual-qty jost">
                        {target.quantity || 1}
                      </div>
                      <div className="plus-btn-qty inc-dec-btn-rel-cont-menu">
                        <div
                          className="btn-section-tap-mask"
                          onClick={() => {
                            const quantity = (Number(target.quantity) || 1) + 1;
                            setQuantity({
                              quantity,
                              id_bundle,
                            });
                          }}
                        />
                        <i className="fa fa-plus-square" />
                      </div>
                    </div>
                  </div>
                  <div className="my-order-product-price jost">
                    {totalLine} {totalLine === 1 ? "leu" : "lei"}
                  </div>
                </div>
              </div>
            );
          })}
          {(bundleLines.length === 0 || totalCartProducts === 0) && (
            <div className="my-order-product">
              <div
                className={`my-order-product-name jost${
                  errors.cartProducts && showErrors ? " error_text" : ""
                }`}
              >
                <div> Niciun produs </div>
              </div>
            </div>
          )}
          <div
            className="final-price"
            style={enable_digital_menu_order ? { padding: "20px 0" } : {}}
          >
            <div className="total-text-price jost">TOTAL</div>
            <div className="total-num-price jost">{totalText}</div>
          </div>
          {enable_digital_menu_order && (
            <React.Fragment>
              <MenuSelect
                value={selectedTableIndex}
                selectedOption={objectPath.get(tables, selectedTableIndex)}
                options={tables}
                labelPath={"table_name"}
                onChange={(event) => {
                  setSelectedTableIndex(event.target.value);
                }}
                error={errors.id_table && showErrors}
                defaultLabel={"Selectează masa"}
              />
              <MenuButton
                onClick={submitMenuOrder}
                label={"Confirmă & cheamă chelner"}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
