// @flow
import * as React from "react";
import "./FeedbackPopup.scss";
import { Emoticon } from "../../Emoticon/Emoticon";
import PopupFrame from "../Frame";
import Button from "../../Button/Button";
import axios from "axios";
import $ from "jquery";

const emoticons = ["cry", "sad", "whatever", "satisfied", "very_satisfied"];

export default function FeedbackPopup({
  general_color,
  setLoader,
  text_color,
  frameProps,
  is_mobile,

  product_separator_color,
  id_venue,
  white_theme,
  id_delivery,
}) {
  const [feedback_text, set_feedback_text] = React.useState("");
  const [feedback_score, set_feedback_score] = React.useState(0);
  const [errorText, setErrorText] = React.useState();
  const [secondPhase, setSecondPhase] = React.useState();

  function submitResponse() {
    if(feedback_score === 0) {
      setErrorText("Te rugăm să selectezi o opțiune pentru a putea trimite formularul.");
      return;
    }
    setLoader(true);
    axios
      .post("feedback", {
        feedback_score,
        feedback_text,
        id_delivery,
        id_venue,
        send_feedback: 1,
      })
      .then(({ data }) => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("error while sending feedback", err);
      });
    setSecondPhase(true);
  }
  function alterModalHeight() {
    if (!is_mobile) return;

    setTimeout(() => {
      $(".__feedback__modal").css("height", window.innerHeight + "px");
      $(".__feedback__modal").animate(
        { scrollTop: $(".feedback-frame-popup").height() },
        100,
        () => {}
      );
    }, 500);
  }
  function redoModalHeight() {
    if (!is_mobile) return;

    $(".__feedback__modal").css("height", "");
  }
  return (
    <PopupFrame className="feedback-frame-popup" {...frameProps}>
      {!secondPhase && (
        <div className="feedback-container">
          <div className="feedback-title" style={{ color: general_color }}>
            {" "}
            Formular de feedback{" "}
          </div>
          <div className="feedback-paragraph" style={{ color: general_color }}>
            {" "}
            Cât de mulțumit ești de comanda primită?{" "}
          </div>
          <div className="feedback-icons-container">
            {emoticons.map((type, index) => (
              <Emoticon
                type={type}
                onClick={() => {
                  setErrorText("");
                  set_feedback_score(index + 1);
                }}
                active={feedback_score === index + 1}
                style={{ color: text_color }}
                // active={true}
                text_color={text_color}
                color_disabled={general_color}
              />
            ))}
            {errorText && <div className="feedback-error">{errorText}</div>}
          </div>
          <div
            className="feedback-paragraph input-title"
            style={{ color: general_color }}
          >
            Vrei să ne lași și un mesaj? <br /> Spune-ne ce ți-a plăcut sau cum
            ne putem îmbunătăți serviciile:
          </div>
          <div className="feedback-input-container">
            
            <input
              id="feedback_input_id"
              onClick={alterModalHeight}
              //   onFocus={alterModalHeight}
              onBlur={redoModalHeight}
              placeholder="Mesaj"
              style={{ color: text_color, borderColor: general_color }}
              value={feedback_text}
              onChange={(event) => set_feedback_text(event.target.value)}
              className="feedback-input"
            />
          </div>
          <div className="feedback-button-container">
            
            <Button
              color={general_color}
              borderColor={general_color}
              text={"Trimite formularul"}
              onClick={submitResponse}
              disabled_style={{
                borderColor: product_separator_color,
                color: white_theme ? "white" : "black",
              }}
            />
          </div>
        </div>
      )}
      {secondPhase && (
        <div className="feedback-container second-phase">
          <div className="feedback-title" style={{ color: general_color }}>
            Mulțumim pentru feedback!
          </div>
          <div
            className="feedback-second-title"
            style={{ color: general_color }}
          >
            Dacă am fost pe placul tău, te așteptăm <br /> cu o nouă comandă!
          </div>
          <div className="feedback-button-container">
            <Button
              color={general_color}
              borderColor={general_color}
              text={"Fă o nouă comandă"}
              onClick={frameProps.on_close_generic_modal}
            />
          </div>
        </div>
      )}
    </PopupFrame>
  );
}
