// @flow
import * as React from 'react';
import Checkbox from '../Checkbox/Checkbox';

export function ReceiptCheckboxSelect({
    items,
    onSelect,
    selected,
    general_color,
    theme_text_style,
    svgColor
}) {
  return (
    <div>
        {
            items.map((option, index) => {
                const {
                    text,
                    disabled,
                    id,
                    key
                } = option;
                return(
                    <Checkbox
                    key={key}
                    onClick={(event) => {
                        onSelect(event, option);
                    }}
                    disabled={disabled}
                    containerClassName={"asap_checkbox_cont"}
                    labelClassName={"asap_checkbox_label"}
                    squareClassName={"asap_square_checkbox"}
                    label={text}
                    label_style={{ ...theme_text_style }}
                    borderColor={general_color}
                    checked={
                    selected === id
                    }
                    svgColor={svgColor}
                />
                )
            })
        }
    
    </div>
  );
};