// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { actions } from "store/reducers/builderReducer";
import { cx, css } from "emotion";

import $ from "jquery";
import { MenuRootContext } from "./MenuRootContext";

export default function IntroPage({ show }) {
  const dispatch = useDispatch();
  const { pageConfig, pageTransitionTime } = useSelector(({ builder }) => ({
    pageConfig:
      objectPath.get(builder, "menuConfig.menuPageConfigs.intro") || {},
    pageTransitionTime:
      objectPath.get(builder, "htmlService.pageTransitionTime") || 0,
  }));
  const menuContextState = React.useContext(MenuRootContext);

  function navigateToCategories() {
    menuContextState.history.push('/meniu/category');
  }

  const [displayNode, setDisplayNode] = React.useState();
  React.useEffect(
    () => {
    //   if (!show) {
    //     setTimeout(() => {
    //       setDisplayNode(false);
    //     }, pageTransitionTime);
    //   } else {
    //     setDisplayNode(true);
    //   }
    },
    [show]
  );
  React.useEffect(() => {
    if(displayNode) {
      $("#menu-layout-container").scrollTop(0); //###scroll point
    }
  }, [displayNode]);
  return !displayNode && false ? (
    ""
  ) : (
    <div className="intro-bottom-section intro-content-container">
      <div className="intro-btn" onClick={navigateToCategories}>
        <div className="intro-btn-text poppins">Vezi meniu</div>
        <div className="intro-btn-arrow">
          <i className="fa fa-arrow-right arrow-intro"> </i>
        </div>
      </div>
    </div>
  );
}
