import { actions } from "../../../store/reducers/builderReducer";

export function menuActions(dispatch) {
    return {
        selectCategoryProducts: function(category, menuPageConfigs) {
            dispatch(
              actions.setMenuPageConfigs({
                menuPageConfigs: {
                  ...menuPageConfigs,
                  product_category: {
                    ...(menuPageConfigs.product_category || {}),
                    title: `${category.category_name}`,
                    categorySelected: {
                      id_category: category.id_category,
                      category_name: category.category_name,
                      products: category.products
                    },
                    topSectionPlaceholder: `Caută în ${category.category_name}`
                  }
                },
              })
            );
        },
        removeInputPlaceholder: function(menuPageConfigs) {
          dispatch(
            actions.setMenuPageConfigs({
              menuPageConfigs: {
                ...menuPageConfigs,
                product_category: {
                  ...(menuPageConfigs.product_category || {}),
                  topSectionPlaceholder: undefined
                }
              },
            })
          );
      },
        closeProductModal: function(menuPageConfigs) {
          dispatch(
            actions.setMenuPageConfigs({
              menuPageConfigs: {
                ...menuPageConfigs,
                product_category: {
                  ...menuPageConfigs.product_category,
                  productModal: null,
                },
              },
            })
          );
        },
        closeCartModal: function() {
          dispatch(
            actions.setCartProductsOpen({
              cartProductsOpen: false,
            })
          );
        }
    };
} 