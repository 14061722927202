// @flow
import * as React from "react";
import { connect } from "react-redux";

import "./Menu.scss";
import { actions, selectors } from "store/reducers/builderReducer";

import $ from "jquery";

import TopSectionMenu from "./TopSectionMenu";
import IntroPage from "./IntroPage";
import CategoryPage from "./CategoryPage";
import ProductCategoryPage from "./ProductCategoryPage";
import MenuProductModal from "./ModalProductModal";
import MenuCartModal from "./MenuCartModal";
import * as helperFunctions from "utils/helper_functions";

import objectPath from "object-path";
import Fade from "./Fade/Fade";
import { cx, css } from "emotion";
import { MenuRootContext } from "./MenuRootContext";
import { Switch, Route, Redirect } from "react-router-dom";

const pageDucks = {
  intro: {
    contentClassName: "content-intro",
    topSection: {
      containerClassName: "intro-top-section",
    },
  },
  category: {
    contentClassName: "content-section",
    topSection: {
      containerClassName: "top-section",
    },
  },
  product_category: {
    contentClassName: "content-section",
    topSection: {
      containerClassName: "top-section",
    },
  },
};

function IntroPageLayout({}) {
  return <IntroPage show={true} />;
}
function CategoryPageLayout() {
  const contextValue = React.useContext(MenuRootContext) || {};
  const { onModalOpen, stopScroll } = contextValue;
  return (
    <CategoryPage
      onModalOpen={onModalOpen}
      stopScroll={stopScroll}
      show={true}
    />
  );
}
function ProductCategoryPageLayout() {
  const contextValue = React.useContext(MenuRootContext) || {};
  const { onModalOpen, stopScroll, productNameValue } = contextValue;
  return (
    <ProductCategoryPage
      onModalOpen={onModalOpen}
      stopScroll={stopScroll}
      productNameValue={productNameValue}
      show={true}
    />
  );
}

class MenuLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      stopScroll: null,
    };
  }
  componentDidMount = () => {};
  componentDidUpdate = (prevProps) => {
    if (prevProps.someModalIsOpened !== this.props.someModalIsOpened) {
      if (!this.props.someModalIsOpened) {
        this.onModalClosing();
      }
    }
  };
  timeoutStopScroll;
  onModalOpen = () => {
    const timeoutStopScroll = setTimeout(() => {
      const scrollTop = helperFunctions.lockMenuBodyContainer(); //###scroll point
      this.setState({
        stopScroll: {
          style: {
            // height: "calc(100vh - 140px)",
            // overflow: "hidden",
          },
          scrollTop,
        },
      });
    }, this.props.productModalTransitionTime);
  };
  onModalClosing = () => {
    clearTimeout(this.timeoutStopScroll);
    if (this.state.stopScroll) {
      const scrollTop = this.state.stopScroll.scrollTop;
      // document.body.style.overflow = "";
      // document.body.style.position = "";
      // document.body.style.height = "";
      // $("#menu-layout-container").scrollTop(scrollTop); //###scroll point

      helperFunctions.unLockMenuBodyContainer();

      // setTimeout(() => {

      // }, 100);
    }
    this.setState({ stopScroll: null });
    this.TimeoutStopScroll = null;
  };

  render() {
    const bruteNumberOfItemCart = this.props.cartProducts.reduce(
      (numberTotal, productLine) => {
        return numberTotal + (Number(productLine.quantity) || 0);
      },
      0
    );

    const ducks =
      objectPath.get(pageDucks, this.props.menuCurrentPage) || ducks["intro"];
    const showProductSectionForced =
      this.props.productNameValue != "" &&
      this.props.menuCurrentPage !== "intro";

    const pageConfig =
      objectPath.get(this.props.menuPageConfigs, this.props.menuCurrentPage) ||
      {};
    const showSearchBar = this.props.menuCurrentPage !== "intro";

    // const showTopSection = this.props.menuCurrentPage !== "intro";

    // const showIntroPage = this.props.menuCurrentPage === "intro";
    // const showCategoryPage =
    //   this.props.menuCurrentPage === "category" && !showProductSectionForced;
    // const showProductCategoryPage =
    //   this.props.menuCurrentPage === "product_category" ||
    //   showProductSectionForced;
    //   const pageConfig =

    return (
      <div
        style={{
          ...(pageConfig.backgroundImage && !pageConfig.backgroundImgOutside
            ? { backgroundImage: `url('${pageConfig.backgroundImage}')` }
            : {}),
          // opacity: this.state.opacity,
        }}
        className={
          // "content-container-menu" +
          // (ducks.contentClassName ? " " + ducks.contentClassName : "")

          cx(
            "content-container-menu" +
              (ducks.contentClassName ? " " + ducks.contentClassName : ""),
            css`
              ${this.props.menuCustomStyle}
            `
          )
        }
      >
        <TopSectionMenu
          pageConfig={pageConfig}
          topSectionDucks={ducks.topSection || {}}
          navigateToPage={(page) => {
            switch (page) {
              case "category":
                this.props.parentValuePassedToContext.history.push(
                  "/meniu/category"
                );
                break;
            }
          }}
          goBack={() => {
            this.props.parentValuePassedToContext.history.goBack();
          }}
        />
        {showSearchBar && <React.Fragment> {this.props.children} </React.Fragment>}
        {!this.props.someModalIsOpened &&
          bruteNumberOfItemCart > 0 &&
          (this.props.menuCurrentPage === "category" ||
            this.props.menuCurrentPage === "product_category") && (
            <div
              className="cart-products"
              onClick={() => {
                this.onModalOpen();
                this.props.setCartProductsOpen({ cartProductsOpen: true });
              }}
            >
              <div className="cart-products-nr poppins">
                {bruteNumberOfItemCart}
              </div>
              <div className="cart-products-text poppins">
                {bruteNumberOfItemCart === 1 ? "produs" : `produse`}
              </div>
            </div>
          )}

        <MenuRootContext.Provider
          value={{
            ...this.props.parentValuePassedToContext,
            onModalOpen: this.onModalOpen,
            stopScroll: this.state.stopScroll,
            productNameValue: this.props.productNameValue,
          }}
        >
          <Switch>
            <Route path="/meniu/intro" component={IntroPageLayout} />
            {showProductSectionForced ? (
              <Route
                path="/meniu/category"
                component={ProductCategoryPageLayout}
              />
            ) : (
              <Route path="/meniu/category" component={CategoryPageLayout} />
            )}
            <Route
              path="/meniu/product_category"
              component={ProductCategoryPageLayout}
            />
            <Redirect exact from="/meniu" to="/meniu/intro" />
          </Switch>
        </MenuRootContext.Provider>

        <MenuProductModal />
        <MenuCartModal
          forceHide={
            this.props.productModalOpen ||
            this.props.menuCurrentPage === "intro"
          }
        />
        {this.props.menuCurrentPage !== "intro" && (
          <div style={{ width: "100%", height: "110px" }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  builder: {
    preferences,
    preferenceUpdate,
    productCategories,
    menuConfig,
    pathname,
    htmlService,
  },
}) => {
  const productModalOpen = Boolean(
    objectPath.get(
      menuConfig,
      "menuPageConfigs.product_category.productModal.id_product"
    )
  );
  const cartProductsOpen = objectPath.get(menuConfig, "cartProductsOpen");
  return {
    preferences,
    preferenceUpdate,
    menuConfig,
    menuCurrentPage: objectPath.get(menuConfig, "menuCurrentPage"),
    menuPageConfigs: objectPath.get(menuConfig, "menuPageConfigs") || {},
    topSectionPlaceholder: objectPath.get(menuConfig, "topSectionPlaceholder") || {},
    productModalTransitionTime:
      objectPath.get(htmlService, "productModalTransitionTime") || 0,
    someModalsAreOpened:
      Boolean(
        objectPath.get(
          menuConfig,
          "menuPageConfigs.product_category.productModal.id_product"
        )
      ) || objectPath.get(menuConfig, "cartProductsOpen"),
    cartProducts: objectPath.get(menuConfig, "cartProducts") || [],
    someModalIsOpened: productModalOpen || cartProductsOpen,
    productModalOpen,
    cartProductsOpen,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLayoutLoader: (payload) => dispatch(actions.setLayoutLoader(payload)),
  setCartProductsOpen: (payload) =>
    dispatch(actions.setCartProductsOpen(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuLayout);
