// @flow
import * as React from "react";

import "./Fade.scss";

export default function Fade({ top, backgroundImageStyle, className }) {
  return (
    <div
      className="fade-abs-cont"
      style={
          {
              ...(
                top
                ? {
                    top: 0,
                  }
                : { bottom: 0 }
              ),
              ...(
                backgroundImageStyle
                ? {
                    ...backgroundImageStyle,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }
                : { }
              )
          }

      }
    > 
    <div className={className}></div>
    <div className={className}></div>
    </div>
  );
}
