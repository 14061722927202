import React from 'react'

const getContainerRef = self => element => {
  self.element = element
}
const listener = self => event => {
  let parent_clicked;
  if(self.props.parent_el_classname){
    let thisTag = event.target; 
    while(thisTag.parentNode && thisTag.parentNode.tagName !== 'BODY') // uppercase in HTML, lower in XML
    {
      if(thisTag.classList.contains(self.props.parent_el_classname)){
        parent_clicked = true;
        break;
      }
      thisTag = thisTag.parentNode;
    }
  }
  if (!parent_clicked && self.element && !self.element.contains(event.target)) {
    return self.props.onClickOutside(event);
  }
}
const onClickInside = self => event => {
  window.addEventListener('click', self.listener);
  self.props.onClickInside(event);
}
export class ClickListener extends React.Component {
  element = null 
  static defaultProps = {
    className: '',
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.watchingOutside) {
      window.removeEventListener('click', this.listener);
    }else{
      window.addEventListener('click', this.listener);
    }
  }
  componentDidMount = () => {
    window.addEventListener('click', this.listener);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.listener);
  }
  listener = listener(this);
  onClickInside = onClickInside(this);
  getContainerRef = getContainerRef(this);
  render() {
    const { as, onClickOutside, onClickInside, watchingOutside, ...props } = this.props
    const Element = as || 'div'
    return (
      <Element {...props} ref={this.getContainerRef} onClick={this.onClickInside}>
        {this.props.children}
      </Element>
    )
  }
}
