// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { actions } from "store/reducers/builderReducer";
import { MenuRootContext } from "./MenuRootContext";

export default function TopSectionMenu({ pageConfig, topSectionDucks, navigateToPage, goBack }) {
  const dispatch = useDispatch();
  const { searchBarOutOfView, title } = useSelector(
    ({ builder: { menuConfig } }) => ({
      title:
        objectPath.get(menuConfig, "menuPageConfigs."+ menuConfig.menuCurrentPage+".title") || "title",
      searchBarOutOfView: objectPath.get(
        menuConfig,
        "layoutService.searchBarOutOfView"
      ),
    })
  );
  const backButton =
    pageConfig.page === "category"
      ? { page: "intro" }
      : pageConfig.page === "product_category"
      ? { page: "category" }
      : null;
  const searchIconHeader = searchBarOutOfView
    ? pageConfig.page === "category" || pageConfig.page === "product_category"
      ? true
      : false
    : null;

  return (
    <div className={topSectionDucks.containerClassName}>
      {pageConfig.page === "intro" && (
        <React.Fragment>
          <div className="intro-title jost">{title}</div>
          <div className="venue-logo">
            <img src={pageConfig.logo} />
          </div>
        </React.Fragment>
      )}
      {backButton && (
        <div
          className="arrow-back-container-top"
          onClick={() => goBack(backButton.page)}
        >
          <i className="fa fa-arrow-left" />
        </div>
      )}

      {pageConfig.page === "category_product" && (
        <React.Fragment>
          <div
            className="arrow-back-container-top"
            onClick={() => navigateToPage("category")}
          >
            <div className="prev-arrow">
              <i className="fa fa-arrow-left" />
            </div>
          </div>
        </React.Fragment>
      )}
      {pageConfig.page !== "intro" && (
        <div className="page-title jost"> {title} </div>
      )}
      {searchIconHeader && (
        <div className="search-button-top">
          <i className="fa fa-search" />
        </div>
      )}
    </div>
  );
}
