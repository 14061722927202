import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { return_parameter } from "utils/helper_functions";

import axios from "axios";
import LayoutLoaderFixed from "components/Loader/LoaderFixed";

// Function to delete the `googtrans` cookie
const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

// Delete the `googtrans` cookie before the app loads
deleteCookie('googtrans');

const { dev } = return_parameter(["dev"]);
window.dev = Number(dev) || 0;

const regExpreLocalhost = /^(http:\/\/localhost)/;
const regExprIpLocalhost = /^(http:\/\/\d+)/;
const regExprDevTest = /^(http[s]?:\/\/dev[\d].poloniq.ro)/;
const regExprDevTestNou = /^(http[s]?:\/\/dev[\d].webdesign-iasi.ro)/;

//production
window.dev =
  regExpreLocalhost.test(window.location.href) ||
    regExprDevTest.test(window.location.href) ||
    regExprIpLocalhost.test(window.location.href) ||
    regExprDevTestNou.test(window.location.href)
    ? 1
    : 0;

window.use_local_db = 0;

if (window.dev === 1) {
  window.disableSubmit =
    regExpreLocalhost.test(window.location.href) ||
      regExprDevTest.test(window.location.href) ||
      regExprDevTestNou.test(window.location.href)
      ? 1
      : 0;
} else {
  window.use_local_db = 0;
  window.disableSubmit = 0;
}

//don't allow localhost api url in production mode or https dev
if (window.use_local_db === 1) {
  if (regExpreLocalhost.test(window.location.href)) {
    window.use_local_db = 0;
  }
}

if (window.use_local_db === 1) {
  window.baseUrl = "http://192.168.0.131/api_poloniq/";
  axios.defaults.baseURL = "http://192.168.0.131/api_poloniq/";
} else {
  window.baseUrl = "https://api.poloniq.ro/";
  axios.defaults.baseURL = "https://api.poloniq.ro/";
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LayoutLoaderFixed />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
