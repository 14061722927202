import React, { useEffect } from "react";

import Checkbox from "reusableComponents/Checkbox/Checkbox";

import "./Tips.scss";

export default function Tips({
  tips_main_text,
  tips_other_text,
  tip_percentages,
  general_color,
  product_separator_color,
  text_color,
  inner_text_color,
  theme_text_style,
  selectTipPercentage,
  selected,
  is_mobile,
}) {
  const [inputValue, setInputValue] = React.useState();

  if (!Array.isArray(tip_percentages)) {
    tip_percentages = [
      {
        percentage_value: 5,
      },
      {
        percentage_value: 10,
      },
    ];
  }
  function onChangeCustomSum(value) {
    let newVal = "";
    for (let i = 0; i < value.length && newVal.length < 3; i++) {
      if (value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
        newVal += value[i];
      }
    }

    selectTipPercentage({
      custom_value: newVal,
    });
    setInputValue(newVal);
  }
  useEffect(
    () => {
      if (selected.custom_value) {
        setInputValue(selected.custom_value);
      }
    },
    [selectTipPercentage]
  );
  return (
    <div className="tips-container">
      <div
        style={{
          backgroundColor: product_separator_color,
        }}
        className={`section_divider`}
      />
        <div
          style={{ ...theme_text_style }}
          className="tips-main-text text-tip"
        >
          {tips_main_text || ""}
        </div>
      <div className="tips-percentages">
        {tip_percentages.map(({ percentage_value, percentage_text }, index) => (
          <Checkbox
            key={`checkbox_percentage_${index}`}
            onClick={(event) => {
              selectTipPercentage({ percentage_value });
            }}
            containerClassName={""}
            labelClassName={"text-tip"}
            squareClassName={""}
            label={percentage_text || `${percentage_value}%`}
            label_style={{ ...theme_text_style }}
            borderColor={general_color}
            checked={percentage_value === selected.percentage_value}
            svgColor={inner_text_color}
          />
        ))}
        {tips_other_text && (
          <Checkbox
            key={`checkbox_percentage_other_id`}
            key={`checkbox_percentage_${"other"}`}
            onClick={(event) => {
              const custom_value = selected.custom_value || inputValue || "";
              onChangeCustomSum(custom_value);
              if (document.getElementById("input_checkbox_other_tip")) {
                setTimeout(() => {
                  document
                    .getElementById("input_checkbox_other_tip")
                    .setAttribute("value", custom_value);
                }, 100);
              }
            }}
            containerClassName={""}
            labelClassName={"text-tip"}
            squareClassName={""}
            labelInput={{
              onChange: (event) => onChangeCustomSum(event.target.value),
              is_mobile,
              nodeOnFocusMobile: document.getElementById("receipt_command__"),
              value: inputValue,
              className: "text-tip",
              placeholder: "",
              style: {
                borderColor: general_color,
                color: text_color
              },
              id: "input_checkbox_other_tip",
            }}
            label={tips_other_text}
            labelRight={"lei"}
            label_style={{ ...theme_text_style }}
            borderColor={general_color}
            checked={selected.percentage_value == null}
            svgColor={inner_text_color}
          />
        )}
      </div>
    </div>
  );
}
