import defaultBgImage from "assets/img/background.png";
/** 
 * @typedef {Object} IHomeState
 * @property {Object} FINAL_RULE
 * @property {String} age_check_needed
 * @property {Boolean} always_show_location
 * @property {String} analytics_tracking_id
 * @property {String} background_color
 * @property {String} background_image
 * @property {String} bank_account
 * @property {Array} blocked_program
 * @property {String} cartDatepickerCss
 * @property {Array} cart_required_terms_text
 * @property {Number} category_nav_bar_scroll_duration
 * @property {Object} center
 * @property {String} client_email
 * @property {String} client_mesaj
 * @property {String} client_nume
 * @property {String} client_persoane
 * @property {String} client_telefon
 * @property {Object} command_cart_products
 * @property {Array} command_categories
 * @property {Object} command_closed_content_popup
 * @property {Boolean} command_section_is_higher
 * @property {String} company_address
 * @property {String} company_name
 * @property {String} confTermsLink
 * @property {Number} cookies
 * @property {String} cookiesTermsLink
 * @property {String} cui
 * @property {Number} currentDistance
 * @property {String} currentMinDeliveryDate
 * @property {Array} date_blocate_array
 * @property {String} date_string_reservation
 * @property {Array} day_closed
 * @property {Number} default_delivery
 * @property {Boolean} deliver
 * @property {Boolean} delivery_asap_only
 * @property {Number} delivery_price
 * @property {Array} delivery_price_array
 * @property {Array} delivery_price_array_intervals
 * @property {Boolean} disable_asap
 * @property {Boolean} disable_delivery
 * @property {Boolean} disable_delivery_hour
 * @property {Boolean} disable_delivery_option
 * @property {Boolean} disable_delivery_with_delivery
 * @property {Boolean} disable_gallery_section
 * @property {Boolean} disable_menu_link
 * @property {Boolean} disable_non_contact
 * @property {Boolean} disable_online_payment
 * @property {Boolean} disable_order_section
 * @property {Boolean} disable_pickup
 * @property {Boolean} disable_program_popup
 * @property {Boolean} disable_reservations
 * @property {Boolean} disable_venue_program
 * @property {String} disabled_color
 * @property {Boolean} enable_card_payment
 * @property {Boolean} enable_cash_payment
 * @property {Boolean} enable_promocode
 * @property {Boolean} enable_tips
 * @property {Boolean} eroare
 * @property {Boolean} exclude_shipping_for_min_order
 * @property {String} facebook_pixel_id
 * @property {String} fb_page_url
 * @property {String} first_screen_background_image
 * @property {String} first_screen_logo
 * @property {String} first_screen_logo_className
 * @property {String} first_screen_title
 * @property {Boolean} forbiden_interval
 * @property {Boolean} forbiden_interval_allow_btns
 * @property {Number} free_delivery_from
 * @property {String} general_color
 * @property {String} google_tag_gtm_id
 * @property {Boolean} hide_cart_optional_checkbox
 * @property {String} hourSelectCss
 * @property {String} hour_reservation
 * @property {String} hour_reservation_stay
 * @property {String} id_venue
 * @property {String} inner_text_color
 * @property {Object} isFetchingScrollAnim
 * @property {Boolean} is_mobile
 * @property {String} last_screen_address
 * @property {String} last_screen_email
 * @property {String} last_screen_hours
 * @property {String} last_screen_latitude
 * @property {String} last_screen_longitude
 * @property {String} last_screen_phone
 * @property {String} last_screen_program
 * @property {Array} markers
 * @property {String} menu_item_text
 * @property {String} minDeliveryDate
 * @property {Null} minDeliveryDateBasedOnCart
 * @property {Number} min_order
 * @property {Number} min_order_initial
 * @property {Array} modals
 * @property {String} name_venue
 * @property {Object} open_loader
 * @property {Array} ore_blocate_array
 * @property {String} pin
 * @property {String} powerByPhoto
 * @property {Number} preferenceUpdate
 * @property {Object} productDeliveryRules
 * @property {Array} product_items
 * @property {String} product_separator_color
 * @property {Boolean} products_have_image
 * @property {Array} program
 * @property {Array} program_delivery
 * @property {Array} program_delivery_rules
 * @property {Array} program_delivery_string
 * @property {Array} program_list_elements
 * @property {Array} program_venue
 * @property {String} promoValue
 * @property {Object} receipt_dropdown
 * @property {String} reg_com
 * @property {String} required_terms_checkbox_warning
 * @property {String} reservationDatepickerCss
 * @property {String} reservationFormularCss
 * @property {String} reservation_max_people
 * @property {String} reservation_min_people
 * @property {String} reservation_promotion_message_from
 * @property {String} second_screen_menu_link
 * @property {String} second_screen_subtitle
 * @property {String} second_screen_title
 * @property {Array} sectionsOrdered
 * @property {Number} sel_command_cat_index
 * @property {Object} settings
 * @property {Boolean} shippingFromShowErrors
 * @property {Object} shipping_form
 * @property {Array} shipping_hour_items
 * @property {Boolean} showErrors
 * @property {String} sizesSelectCss
 * @property {Array} sliderImages
 * @property {Boolean} some_products_have_image
 * @property {Boolean} sticky_command_navbar
 * @property {String} termsAndConditonLink
 * @property {String} text_color
 * @property {String} third_screen_right_image
 * @property {Object} tipValue
 * @property {String} tips_main_text
 * @property {String} tips_other_text
 * @property {Boolean} trimitere_ok
 * @property {String} unique_key
 * @property {Boolean} use_cart_required_terms_checkbox
 * @property {Boolean} use_reservation_stay_form
 * @property {Array} valid_hours_reservation
 * @property {Array} valid_hours_reservation_stay
 * @property {Boolean} venue_active
 * @property {Array} venue_counties
 * @property {Object} venue_county_names
 * @property {Object} venue_locations
 * @property {Number} zoom
 */


/**
 * 
 * @param {IHomeState} state 
 */
export function generateReservationDatepickerCss(state) {
  return `
    .react-datepicker__day,
    .react-datepicker__day--today
    {
      
      &:hover{
        color: ${state.text_color};
      };
      color: ${state.general_color};
    };
    .react-datepicker__day--disabled {
      &:hover {
        color: ${state.general_color};
      }
    }
    .react-datepicker {
      ${
        state.background_color
          ? "background-color:" +
            state.background_color +
            ";" +
            "background-image: none!important;"
          : "background-color: transparent;" +
            "background-repeat: repeat;" +
            "background-image: url(" +
            (state.background_image || defaultBgImage) +
            ");"
      }
      border: 4px solid ${state.general_color}!important;
    }
    .react-datepicker__header 
    {
      background-color: transparent;
      
    }

    .react-datepicker__navigation--previous {
      border-right-color: ${state.general_color};
    }
    .react-datepicker__navigation--next {
      border-left-color: ${state.general_color};
    }
    .react-datepicker__day-name,
    .react-datepicker__day-name.--selected,
    .react-datepicker__current-month 
      {
      color: ${state.general_color};
    }
    .react-datepicker__day-names,
    .react-datepicker__header  {
      .react-datepicker__day-name {
        color: ${state.general_color};
      }
      border-color: ${state.general_color};
    }
    .react-datepicker__input-container {
      input {
        color: ${state.text_color}!important;
      }
    }
  `;
}
/**
 * 
 * @param {IHomeState} state 
 */
export function generateHourSelectCss(state) {
    return `
    .option-select-label-selected {
        color: ${state.text_color};
        border-color: ${state.general_color};
    }
    .simple-dropdown-option {
        color: ${state.general_color}!important;
        &:hover {
            color: ${state.text_color}!important;
        }

    }
    .simple-dropdown-option-container {
        border-color: ${state.general_color};
        color: ${state.general_color};
        
        &::-webkit-scrollbar-thumb {
            background-color: ${state.white_theme
                ? state.text_color
                : state.text_color}!important;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent !important;
        }
        ${state.background_color
        ? "background-color:" +
            state.background_color +
            ";" +
            "background-image: none!important;"
        : "background-color: transparent;" +
            "background-repeat: repeat;" +
            "background-image: url(" +
            (state.background_image || defaultBgImage) +
            ");"}
    }

    `
}
/**
 * 
 * @param {IHomeState} state 
 */
export function generateSizeSelectCss(state) {
  return `
  .option-select-label-selected {
      color: ${state.text_color};
      border-color: ${state.general_color};
      svg {
        polygon {
          fill: ${state.text_color}
        }
      }
  }
  .simple-dropdown-option {
      color: ${state.general_color}!important;
      &:hover {
          color: ${state.text_color}!important;
      }

  }
  .simple-dropdown-option-container {
      border-color: ${state.general_color};
      color: ${state.general_color};
      
      &::-webkit-scrollbar-thumb {
          background-color: ${state.white_theme
              ? state.text_color
              : state.text_color}!important;
      }
      &::-webkit-scrollbar-track {
          background-color: transparent !important;
      }
      ${state.background_color
      ? "background-color:" +
          state.background_color +
          ";" +
          "background-image: none!important;"
      : "background-color: transparent;" +
          "background-repeat: repeat;" +
          "background-image: url(" +
          (state.background_image || defaultBgImage) +
          ");"}
  }

  `
}
/**
 * 
 * @param {IHomeState} state 
 */
export function generateCartDatePickerCss(state) {
    return `

    .react-datepicker__day,
    .react-datepicker__day--today
    {
    
    &:hover{
    color: ${state.text_color};
    };
    color: ${state.general_color};
    };
    .react-datepicker__day--disabled {
    &:hover {
    color: ${state.general_color};
    }
    }
    .react-datepicker {
    ${
        state.background_color
        ? "background-color:" +
            state.background_color +
            ";" +
            "background-image: none!important;"
        : "background-color: transparent;" +
            "background-repeat: repeat;" +
            "background-image: url(" +
            (state.background_image || defaultBgImage) +
            ");"
    }
    border: 4px solid ${state.general_color}!important;
    }
    .react-datepicker__header 
    {
    background-color: transparent;
    
    }
    .react-datepicker__navigation--previous {
    border-right-color: ${state.general_color};
    }
    .react-datepicker__navigation--next {
    border-left-color: ${state.general_color};
    }
    .react-datepicker__day-name,
    .react-datepicker__day-name.--selected,
    .react-datepicker__current-month 
    {
    color: ${state.general_color};
    }
    .react-datepicker__day-names,
    .react-datepicker__header  {
    .react-datepicker__day-name {
    color: ${state.general_color};
    }
    border-color: ${state.general_color};
    }
    
    .react-datepicker__input-container {
    input {
    color: ${state.text_color}!important;
    }
    }
    .select-selected.select-arrow-active,
    .select-selected {
    color: ${state.text_color}!important;
    }
    `;
}
/**
 * 
 * @param {IHomeState} state 
 */
export function generateReservationFormularCss(state) {
    return `.select-selected {
        color: ${state.text_color};
      }
      textarea::placeholder,
      input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${state.text_color};
        opacity: 1; /* Firefox */
      }

      textarea:-ms-input-placeholder,
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${state.text_color};
      }

      textarea::-ms-input-placeholder,
      input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${state.text_color};
      }
      .select-items {
        ${state.background_color
          ? `
                background-color: ${
                  state.background_color
                }!important;
                background-image: none!important;
              `
          : `
                background-color: transparent!important;
                background-repeat: repeat!important;
                background-image: url(${state
                  .background_image || defaultBgImage})!important;
              `}
        &::-webkit-scrollbar-thumb {
          background-color: ${state.white_theme
            ? state.text_color
            : state.text_color}!important;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent !important;
        }
        border-color: ${state.general_color}!important;
        & > div {
          color: ${state.general_color}!important;
          &:hover {
            color: ${state.text_color}!important;
          }
        }
      }
    `
}
/**
 * 
 * @param {IHomeState} state 
 */
export function generateProductItemCss(state) {
  return `
  .assoc-category-popup-main-wrapper {
    ${
      state.background_color
        ? "background-color:" +
          state.background_color +
          ";" +
          "background-image: none!important;"
        : "background-color: transparent;" +
          "background-repeat: repeat;" +
          "background-image: url(" +
          (state.background_image || defaultBgImage) +
          ");"
    }
    .assoc-category-popup-inner {
      
      border-color: ${state.general_color}!important;
      
      .assoc-category-popup-description {
        border-color: ${state.general_color}!important;
      }
      .assoc-category-popup-category-check {
        border-color: ${state.product_separator_color};
      }

      .assoc-category-popup-image-wrapper {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .assoc-category-popup-title,
      .assoc-category-popup-price,
      .assoc-category-popup-category-title {
        color: ${state.general_color};
      }
      .assoc-category-popup-category-title-info,
      .assoc-category-popup-category-desc,
      .assoc-category-popup-description {
        &:not(.error_text) {
          color: ${state.text_color};
        }
      }
    }
    .assoc-close-btn {
      path {
        fill: ${state.general_color};
      }
    }
  }
  `
}
