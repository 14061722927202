// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";

import * as helperFunctions from "utils/helper_functions";
import {
    cartProductsActions,
    convertAssocToProductForm,
} from "utils/actionHelpers";
import { actions } from "store/reducers/builderReducer";
import { cx, css } from "emotion";

import MenuCheckBoxItem from "./MenuCheckBoxItem/MenuCheckBoxItem";
import { menuActions } from "./menuActions";

export default function MenuProductModal() {
    const dispatch = useDispatch();
    const {
        productModal,
        menuPageConfigs,
        productModalTransitionTime,
        cartProducts,
        id_venue,
        digital_menu_default_product_image,
    } = useSelector(
        ({ builder: { menuConfig, htmlService, preferences } }) => ({
            productModal:
                objectPath.get(
                    menuConfig,
                    "menuPageConfigs.product_category.productModal"
                ) || {},
            menuPageConfigs: objectPath.get(menuConfig, "menuPageConfigs"),
            productModalTransitionTime: Number.isInteger(
                objectPath.get(menuConfig, "productModalTransitionTimeCustom")
            )
                ? objectPath.get(menuConfig, "productModalTransitionTimeCustom")
                : htmlService.productModalTransitionTime || 0,
            cartProducts: objectPath.get(menuConfig, "cartProducts") || [],
            id_venue: objectPath.get(preferences, "id_venue"),
            digital_menu_default_product_image: objectPath.get(
                preferences,
                "digital_menu_default_product_image"
            ),
        })
    );

    const [productAssigned, setProductAssigned] = React.useState(null);
    const [assoc, setAssoc] = React.useState({});
    const [containerStyle, setContainerStyle] = React.useState({});

    function onCloseProductModal() {
        menuActions(dispatch).closeProductModal(menuPageConfigs);
    }
    function listen(event) {
        const hasClickedOutside = helperFunctions.listenOutsideClick(
            event,
            "#id-product-modal-menu",
            ".products"
        );

        if (hasClickedOutside) {
            onCloseProductModal();
        }
    }
    function onAddProductsToCart() {
        if (productAssigned) {
            const productsToAdd = [
                productAssigned,
                ...Object.entries(assoc).reduce(
                    (restOfProducts, [id_product, productAssoc]) => {
                        if (
                            productAssoc.checkedByUser &&
                            (productAssoc.unique_assoc !== "1" ||
                                restOfProducts.length === 0)
                        ) {
                            return [...restOfProducts, productAssoc];
                        }
                        return restOfProducts;
                    },
                    []
                ),
            ];
            cartProductsActions(dispatch, id_venue).addProductsToCart({
                cartProducts,
                productsToAdd,
            });
            onCloseProductModal();
        } else {
            console.log(
                "Unexpected: click add product to cart => no product assined to modal"
            );
        }
    }
    React.useEffect(
        () => {
            if (!productModal.id_product) {
                setTimeout(() => {
                    setProductAssigned(null);
                    setAssoc({});
                    //###investigate
                    helperFunctions.unLockMenuBodyContainer();
                    // document.body.style.overflow = "";
                    // document.body.style.position = "";
                    // document.body.style.height = "";
                    window.removeEventListener("click", listen);
                }, productModalTransitionTime);
                window.removeEventListener("click", listen);
            } else {
                setProductAssigned({ ...productModal });
                if (productModal.has_assoc === "1") {
                    setAssoc(
                        (productModal.assoc || [])
                            .map((productAssocItem) =>
                                convertAssocToProductForm(
                                    productAssocItem,
                                    productModal.id_product,
                                    productModal.unique_assoc
                                )
                            )
                            .reduce(
                                (prevState, productAssoc) => ({
                                    ...prevState,
                                    [productAssoc.id_product]: {
                                        ...productAssoc,
                                    },
                                }),
                                {}
                            )
                    );
                }
            }
        },
        [productModal.id_product]
    );
    React.useEffect(
        () => {
            if (!productAssigned) {
                window.removeEventListener("click", listen);
            } else {
                setTimeout(() => window.addEventListener("click", listen), 100);
            }
            setContainerStyle({
                height: window.innerHeight - 70,
            });
        },
        [productAssigned]
    );
    const closingStyle = {
        opacity: 0,
        transform: "translate(0, calc(100vh - 70px))",
    };
    return !productAssigned ? (
        ""
    ) : (
        <div
            className={cx(
                `menu-modal-generic-container${
                    productModal.id_product
                        ? " modal-open-animation-class"
                        : " modal-closing-animation-class"
                }`,
                css`
                    animation-duration: ${productModalTransitionTime / 1000}s;
                `
            )}
        >
            <div
                id="id-product-modal-menu"
                venue={productModal.id_venue}
                className={cx(
                    `product-description${
                        productModal.id_product
                            ? " modal-open-animation-class-fr-chid"
                            : " modal-closing-animation-class-fr-chid"
                    }`,
                    css`
                        animation-duration: ${productModalTransitionTime /
                            1000}s;
                    `
                )}
                style={{ ...containerStyle }}
            >
                <div className="close-description">
                    <div className="close-rel-mask-desk-menu">
                        <i className="fa fa-times x-btn" />
                        <div
                            className="btn-section-tap-mask"
                            onClick={onCloseProductModal}
                        />
                    </div>
                </div>

                <div className="scroll_container-desc-">
                    <div className="product-description-img">
                        <img
                            className="image-product"
                            alt={productAssigned.product_name}
                            src={
                                productAssigned.product_image ||
                                `https://api.poloniq.ro/uploads/digital_menu_assets/${id_venue}/${digital_menu_default_product_image}`
                            }
                        />
                        {/* <div className="fade" /> */}
                    </div>
                    <div className="product-description-width">
                        <div className="product-description-title jost">
                            {productAssigned.product_name}
                        </div>
                        <div className="product-description-price poppins">
                            {Number(productAssigned.product_price) || 0}{" "}
                            {(Number(productAssigned.product_price) || 0) === 1
                                ? "leu"
                                : "lei"}
                        </div>
                        <div className="product-description-text poppins">
                            {productAssigned.product_description}
                        </div>
                        <div className="modal-product-assoc-container">
                            {Object.entries(assoc).map(
                                ([assocItemId, assocItem]) => (
                                    <MenuCheckBoxItem
                                        label={`${assocItem.product_name} (+${
                                            Number(assocItem.product_price) ===
                                            1
                                                ? "1 leu"
                                                : assocItem.product_price +
                                                  " lei"
                                        })`}
                                        key={assocItemId}
                                        value={assocItem.checkedByUser}
                                        onChange={() => {
                                            setAssoc(
                                                !assocItem.checkedByUser &&
                                                    productAssigned.unique_assoc ===
                                                        "1"
                                                    ? Object.entries(assoc).map(
                                                          ([idIt, it]) => ({
                                                              ...it,
                                                              checkedByUser:
                                                                  assocItemId ===
                                                                  idIt
                                                                      ? true
                                                                      : false,
                                                          })
                                                      )
                                                    : {
                                                          ...assoc,
                                                          [assocItemId]: {
                                                              ...assoc[
                                                                  assocItemId
                                                              ],
                                                              checkedByUser: !assoc[
                                                                  assocItemId
                                                              ].checkedByUser,
                                                          },
                                                      }
                                            );
                                        }}
                                    />
                                )
                            )}
                        </div>
                    </div>

                    <div style={{ height: "150px", width: "100%" }} />
                </div>

                <div className="product-desc-menu-contianer-btn">
                    <div
                        className="product-description-btn poppins"
                        onClick={onAddProductsToCart}
                    >
                        Selectează produs
                    </div>
                </div>
            </div>
        </div>
    );
}
