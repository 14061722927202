import React, { useEffect, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import objectPath from "object-path";
import Loader from "components/Loader/Loader";
import * as builder from "store/reducers/builderReducer";
import * as auth from "store/reducers/authReducer";
import * as helperFunctions from "utils/helper_functions";
import axios from "axios";

import parse from "html-react-parser";

import {
  cartProductsActions,
  convertAssocToProductForm,
} from "utils/actionHelpers";

/**
 * Used to synchronize current layout , `layoutConfig` and
 * `htmlClassService` with redux store.
 */
class DataInitializer extends React.Component {
  apiService;
  componentDidMount = () => {
    this.getPreferencesAndCategories();
  };
  getPreferencesAndCategories = () => {
    const slugCallLink = helperFunctions.getUniqueSlugLocation();
    this.props.setLayoutLoader({ layoutLoader: { open: true, duration: 1 } });
    axios
      .get(slugCallLink)
      .then((response) => {
        if (response.data.id_venue) {
          if (response.data.redirect_to_custom_domain && window.dev !== 1) {
            window.location.replace(response.data.redirect_to_custom_domain);
          }
          let delivery_price_array;
          let delivery_price_array_intervals;
          if (Array.isArray(response.data.delivery_price_array)) {
            const response_delivery_price_array = response.data.delivery_price_array.map(
              (item) => ({
                ...item,
                delivery_price: Number(item.delivery_price) || 0,
                priority: Number(item.priority) || 0,
              })
            );

            /** by distance  */
            delivery_price_array = response_delivery_price_array.filter(
              (item) => item.check_type === "distance"
            );

            /** by price  */
            delivery_price_array_intervals = response_delivery_price_array.filter(
              (item) => item.check_type === "price"
            );
          }
          
          this.props.setAllPreferences({
            preferences: {
              ...response.data,
              delivery_price_array,
              delivery_price_array_intervals,
              //menu_color_body_bg: "#fdf0e8",
              //menu_color_bg_btn: "#ff7f00",
              //menu_color_text_btn: "white",
              //menu_color_negative: "black",
              //menu_color_category_nr_txt: "#f4b58e",
              //menu_color_bg_modal_product: "white",
              //menu_color_text_description: "#6b6b6b",
              //menu_color_modal_bg_ext: "rgba(222,197,182,.7)",
              //menu_color_secundary_negative: "#989ea3"
              //menu_color_cart_item_border: "#f1f3f5",
              //menu_color_product_item_bg: "white",
              //menu_color_product_weight_bg: "#eef0f1",
              //menu_color_product_weight_txt: "#989ea3",
              //menu_color_cart_modal_bg: "white",
              //menu_color_product_modal_bg: "transparent",
              //menu_color_checkbox_fill: "black",
              //menu_color_product_item_bg_checked: "#faede5",
              //menu_color_intro_top_bottom_fade: "white",

              // menu_color_body_bg: "red",
              // menu_color_bg_btn: "grey",
              // menu_color_text_btn: "yellow",
              // menu_color_negative: "blue",
              // menu_color_category_nr_txt: "brown",
              // menu_color_bg_modal_product: "grey",
              // menu_color_text_description: "red",
              // menu_color_modal_bg_ext: "blue",
              // menu_color_secundary_negative: "yellow",
              // menu_color_cart_item_border: "red",
              // menu_color_product_item_bg: "green",
              // menu_color_product_item_bg_checked: "rgba(0,0,0,0.3)",
              // menu_color_product_weight_bg: "red",
              // menu_color_product_weight_txt: "violet",
              // menu_color_cart_modal_bg: "#9fab00",
              // menu_color_product_modal_bg: "#ff00d4",
              // menu_color_checkbox_fill: "green",
              // menu_color_intro_top_bottom_fade: "black"
            },
          });
          this.getCategoriesAndProductCatalog(response.data.id_venue)
            .then(
              () => {
                this.props.incrementPreferenceUpdate();
                //setTimeout( () => this.props.setLayoutLoader({layoutLoader: { open: false } }),200);
              },
              (error) => {
                console.log("Rejection while fetching products", error);
                this.props.incrementPreferenceUpdate();
                //setTimeout( () => this.props.setLayoutLoader({layoutLoader: { open: false } }),200);
              }
            )
            .catch((error) => {
              console.log("Error catch while fetching products", error);
              this.props.incrementPreferenceUpdate();
              //setTimeout( () => this.props.setLayoutLoader({layoutLoader: { open: false } }),200);
            });
        } else {
          this.props.setAllPreferences({ preferences: {} });
          this.props.setAllProductCategories({ productCategories: [] });
          this.props.incrementPreferenceUpdate();
          //setTimeout( () => this.props.setLayoutLoader({layoutLoader: { open: false } }),200);
        }
      })
      .catch((err) => {
        console.log("Error while fetching venue id", err);
        this.props.setAllPreferences({ preferences: {} });
        this.props.setAllProductCategories({ productCategories: [] });
        this.props.incrementPreferenceUpdate();
        //setTimeout( () => this.props.setLayoutLoader({layoutLoader: { open: false } }),200);
      });
  };
  getCategoriesAndProductCatalog = (id_venue) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`category/${id_venue}/id_venue`)
        .then(({ data }) => {
          this.props.setAllProductCategories({
            productCategories: data || [],
            id_venue,
          });
          resolve();
        })
        .catch((err) => {
          this.props.setAllProductCategories({ productCategories: [] });
          reject(err);
        });
    });
  };
  render() {
    return "";
  }
}
export default function LayoutInitializer({ children, location, history }) {
  const dispatch = useDispatch();
  const { preferenceUpdate, id_venue } = useSelector(
    ({ builder: { preferenceUpdate, preferences } }) => ({
      preferenceUpdate,
      id_venue: objectPath.get(preferences, "id_venue"),
    })
  );
  function setAllPreferences({ preferences }) {
    dispatch(builder.actions.setAllPreferences({ preferences }));
  }
  function setAllProductCategories({ productCategories, id_venue }) {
    const homeProductCategories = [];
    productCategories.forEach((category) => {
      if (category.active_for_order === "1") {
        const categoryHome = JSON.parse(JSON.stringify(category));
        const categoryHomeProducts = (categoryHome.products || [])
          .filter(({ active_for_order }) => active_for_order === "1")
          .map((product) => ({
            ...product,
          }));

        if (categoryHomeProducts.length > 0) {
          categoryHome.products = categoryHomeProducts;
          homeProductCategories.push({
            ...categoryHome
          });
        }
      }
    });
    dispatch(
      builder.actions.setAllProductCategories({
        productCategories: homeProductCategories,
      })
    );

    const menuProductCategories = [];
    let menuCartFromLocalStorage = helperFunctions.get_info_parsed_l_st_venue(
      id_venue,
      "menu_command_cart"
    );
    const productsRaw = {};
    const productsAssocRaw = {};

    productCategories.forEach((category) => {
      if (category.active_on_location === "1") {
        const categoryMenu = JSON.parse(JSON.stringify(category));
        const categoryMenuProducts = (categoryMenu.products || [])
          .filter(({ active_on_location }) => active_on_location === "1")
          .map((product) => ({
            ...product,
            product_description: parse(product.product_description || ""),
            product_image: product.product_image
              ? `https://api.poloniq.ro/uploads/venues/${id_venue}/products/${
                  product.product_image
                }`
              : product.product_image,
          }));

        if (categoryMenuProducts.length > 0) {
          categoryMenu.products = categoryMenuProducts;
          menuProductCategories.push({
            ...categoryMenu,
          });
          if (menuCartFromLocalStorage) {
            categoryMenuProducts.forEach((pItem) => {
              productsRaw[pItem.id_product] = pItem;
              productsAssocRaw[pItem.id_product] =
                pItem.has_assoc === "1"
                  ? (pItem.assoc || []).reduce(
                      (prv, assoc) => ({
                        ...prv,
                        [assoc.id_assoc]: {
                          ...convertAssocToProductForm(
                            assoc,
                            pItem.id_product,
                            pItem.unique_assoc
                          ),
                        },
                      }),
                      {}
                    )
                  : false;
            });
          }
        }
      }
    });
    if (menuCartFromLocalStorage) {
      menuCartFromLocalStorage = menuCartFromLocalStorage.filter(
        (item) =>
          item &&
          Boolean(item.id_bundle) &&
          Boolean(item.id_product) &&
          Number(item.quantity) &&
          Number(item.quantity) > 0 &&
          (item.id_target
            ? Boolean(
                objectPath.get(
                  productsAssocRaw,
                  `${item.id_target}.${item.id_product}`
                )
              )
            : Boolean(productsRaw[item.id_product]))
      );

      const cartProductsLs = menuCartFromLocalStorage.map((item) => ({
        ...item,
        ...(item.id_target
          ? objectPath.get(
              productsAssocRaw,
              `${item.id_target}.${item.id_product}`
            )
          : productsRaw[item.id_product]),
      }));
      cartProductsActions(dispatch, id_venue).setCartProducts({
        cartProducts: cartProductsLs,
      });
    }
    dispatch(
      builder.actions.setMenuProductCategories({ menuProductCategories })
    );
  }

  function incrementPreferenceUpdate() {
    dispatch(
      builder.actions.setPreferenceUpdate({
        preferenceUpdate: preferenceUpdate + 1,
      })
    );
  }
  function setLayoutLoader({ layoutLoader }) {
    dispatch(builder.actions.setLayoutLoader({ layoutLoader }));
  }

  // Subscribe to history changes and reinitialize on each change.
  useEffect(
    () => {
      // history.listen(({ pathname }) => {
      //   console.log(pathname);
      //   dispatch(builder.actions.updatePathname({
      //     payload: { pathname: pathname.slice(1, pathname.length - 1)}
      //   }));
      // })
      /**
       * Passing `history` and `menuConfig` to `deps` ensures that `useEffect`
       * will cleanup current `history` listener and will dispatch `INIT`
       * with `menuConfig` reference from current render.
       *
       * @see https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect
       */

      const pathname = location.pathname;
      dispatch(
        builder.actions.updatePathname({
          pathname: pathname.slice(1, pathname.length),
        })
      );
      dispatch(
        builder.actions.setMenuCurrentPage({
          menuCurrentPage: builder.selectors.getMenuCurrentPageSlug(pathname),
        })
      );
    },
    [location.pathname]
  );
  return (
    <React.Fragment>
      <DataInitializer
        location={location}
        setAllPreferences={setAllPreferences}
        setAllProductCategories={setAllProductCategories}
        incrementPreferenceUpdate={incrementPreferenceUpdate}
        setLayoutLoader={setLayoutLoader}
      />
      {<React.Fragment> {children} </React.Fragment>}
    </React.Fragment>
  );
}
