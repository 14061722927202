import { actions } from "store/reducers/builderReducer";
import * as helperFunctions from "utils/helper_functions";
import objectPath from "object-path";

export function convertAssocToProductForm(assoc, id_target, unique_assoc) {
  return {
    id_target,
    unique_assoc,
    id_product: assoc.id_assoc,
    product_name: assoc.assoc_name,
    product_price: assoc.assoc_price,
    product_package: assoc.assoc_package,
    id_tax: assoc.assoc_tax,
  }
}

function getTotalProductIndexing(totalCartProducts) {
  const cartProductsIndexing = {};
  totalCartProducts.forEach((product) => {
    if(!product.id_target) {
      cartProductsIndexing[product.id_product] = { on: 1 };
    }
  });
  return cartProductsIndexing;
}
function setCartProductsMenuLocalStorage(cartProducts, id_venue) {
  helperFunctions.set_info_parsed_l_st_venue(
    id_venue,
    "menu_command_cart",
    cartProducts.map(({ id_product, quantity, id_bundle, id_target, unique_assoc }) => ({ id_product, quantity, id_bundle, id_target, unique_assoc }))
  );
}
function getTotalPriceCartProducts({ cartProducts }) {
  const totalCartProducts = cartProducts.reduce((totalPrev, item) => {
    const totalLine =
      (Number(item.quantity) || 1) * (Number(item.product_price) || 0);
    return Math.floor((totalLine + totalPrev)*100000)/100000;
  }, 0);
  return Math.floor((totalCartProducts)*100000)/100000;
}

function groupProductsByBundle(products) {
  const bundles = {};
  products.forEach((product, index) => {
    bundles[product.id_bundle] = {
      ...(bundles[product.id_bundle] || {}),
      [product.id_product]: {...product, indexInCartProducts: index},
    };
  });
  return bundles;
}
function getBundleProductTarget(bundle) {
  const bundleTarget = Object.entries(bundle).find(
    ([id_prod, prod]) => !prod.id_target
  ) || [null, { unique_assoc: "0" }];

  bundleTarget[1].unique_assoc = bundleTarget[1].unique_assoc || "0";
  return bundleTarget;
}

function getBundleThatGroupIsPartOf(cartProducts, productsToAddIndexed) {
  const bundles = groupProductsByBundle(cartProducts);
  const bundlesEntries = Object.entries(bundles);
  const productsToAddIndexedEntries = Object.entries(productsToAddIndexed);
  const [id_target_products_to_add, target_products_to_add] = getBundleProductTarget(productsToAddIndexed);

  for (let i = 0; i < bundlesEntries.length; i++) {
    const [id_bundle, bundle] = bundlesEntries[i];
    const bundleProductEntries = Object.entries(bundle);
    if (bundleProductEntries.length === productsToAddIndexedEntries.length) {
      //bundle target
      const [id_target_bundle, target_bundle] = getBundleProductTarget(bundle);

      const isPartOf = 
        id_target_products_to_add === id_target_bundle && 
        target_products_to_add.unique_assoc === target_bundle.unique_assoc &&
        bundleProductEntries.every(([id_product_bundle, product_bundle]) => {
          if (productsToAddIndexed[id_product_bundle]) {
            return true;
          }
          return false;
        });
      if (isPartOf) {
        return bundlesEntries[i];
      }
    }
  }
  return null;
}
function getBundleLines(cartProducts) {
  const bundles = groupProductsByBundle(cartProducts);
  const bundleLines  = [];
  Object.entries(bundles).forEach(([id_bundle, bundle]) => {
    const [id_target, target] = getBundleProductTarget(bundle);
    const bundleEntries = Object.entries(bundle);
    let totalLine = 0;
    let assoc = [];
    for(let  i = 0; i < bundleEntries.length; i++ ) {
      const [id_product_bundle, product_bundle] = bundleEntries[i];
      if(id_product_bundle !== id_target) {
        assoc.push(product_bundle);
      }
      totalLine += (Number(product_bundle.quantity) || 0) * (Number(product_bundle.product_price) || 0);
      totalLine = Math.floor(totalLine*100000)/100000;
    }
    totalLine = Math.floor(totalLine*100)/100;
    bundleLines.push({
      id_bundle,
      target,
      assoc,
      totalLine,
    });
  });
  return bundleLines;
}

export const cartProductsActions = (dispatch, id_venue) => ({
  addProductsToCart: ({ cartProducts, productsToAdd }) => {
    //check if a bundle like this exists already
    const productsToAddIndexed = productsToAdd.reduce((result, product) => ({
      ...result,
      [product.id_product]: product,
    }), {});

    const bundleNewProductsArePartOf = getBundleThatGroupIsPartOf(
      cartProducts,
      productsToAddIndexed
    );

    if(bundleNewProductsArePartOf) {
      const [id_bundle, bundle] = bundleNewProductsArePartOf;
      Object.entries(bundle).forEach(([id_product, product]) => {
        const quantityNew = (Number(product.quantity) || 0) + 1;
        if(objectPath.get(cartProducts, `${product.indexInCartProducts}.quantity`)) {
          cartProducts[product.indexInCartProducts].quantity = quantityNew;
        }
      });
    } else {
      const [id_target_products_to_add, target_products_to_add] = getBundleProductTarget(productsToAddIndexed);
      const id_bundle = String(Date.now());
      for(let  i = 0; i < productsToAdd.length; i++) {
        cartProducts.push({
          ...productsToAdd[i],
          id_bundle,
          id_target: id_target_products_to_add === productsToAdd[i].id_product ? null : id_target_products_to_add,
          quantity: 1
        });
      }
    }
    const bundleLines = getBundleLines(cartProducts);
    const totalCartProducts = getTotalPriceCartProducts({ cartProducts });
    const cartProductsIndexing = getTotalProductIndexing(cartProducts);
    dispatch(actions.setCartProducts({ cartProducts }));
    dispatch(actions.setBundleLines({ bundleLines }));
    dispatch(actions.setCartProductsIndexing({ cartProductsIndexing }));
    dispatch(actions.setTotalPriceCartProducts({ totalCartProducts }));

    setCartProductsMenuLocalStorage(cartProducts, id_venue);
  },
  setCartProducts: ({ cartProducts }) => {
    const bundleLines = getBundleLines(cartProducts);
    const totalCartProducts = getTotalPriceCartProducts({ cartProducts });
    const cartProductsIndexing = getTotalProductIndexing(cartProducts);
    dispatch(actions.setCartProducts({ cartProducts }));
    dispatch(actions.setBundleLines({ bundleLines }));
    dispatch(actions.setCartProductsIndexing({ cartProductsIndexing }));
    dispatch(actions.setTotalPriceCartProducts({ totalCartProducts }));

    setCartProductsMenuLocalStorage(cartProducts, id_venue);
  },
  setProductQuantity: ({ cartProducts, id_bundle, quantity }) => {
    if(quantity <= 0) {
      cartProducts = cartProducts.filter((productItem) => productItem.id_bundle !== id_bundle); 
    } else {
      for(let  i = 0; i < cartProducts.length; i++) {
        if(cartProducts[i].id_bundle === id_bundle) {
          cartProducts[i].quantity = quantity;
        }
      }
    }
    const bundleLines = getBundleLines(cartProducts);
    const totalCartProducts = getTotalPriceCartProducts({ cartProducts });
    const cartProductsIndexing = getTotalProductIndexing(cartProducts);
    dispatch(actions.setCartProducts({ cartProducts }));
    dispatch(actions.setBundleLines({ bundleLines }));
    dispatch(actions.setCartProductsIndexing({ cartProductsIndexing }));
    dispatch(actions.setTotalPriceCartProducts({ totalCartProducts }));

    setCartProductsMenuLocalStorage(cartProducts, id_venue);
  },
});
