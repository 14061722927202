// @flow
import * as React from "react";

import "./Emoticon.scss";
import { css, cx } from "emotion";
const createHoverClassName = (color) => css`
  &:hover {
    svg {
        fill: ${color}!important;
    }
  }
`;
const templates = {
  cry: {
    title: "Foarte nemulțumit",
    activeColor: "#be3939",
    svg: ({ fill }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ fill }}
      >
        <path d="m340.136719 225.667969c30.695312 0 55.667969-24.972657 55.667969-55.667969h-30c0 14.152344-11.515626 25.667969-25.667969 25.667969-14.152344 0-25.667969-11.515625-25.667969-25.667969h-30c0 30.695312 24.972656 55.667969 55.667969 55.667969zm0 0" />
        <path d="m227.53125 170h-30c0 14.152344-11.515625 25.667969-25.667969 25.667969-14.152343 0-25.667969-11.515625-25.667969-25.667969h-30c0 30.695312 24.972657 55.667969 55.667969 55.667969 30.695313 0 55.667969-24.972657 55.667969-55.667969zm0 0" />
        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-407.019531 181.019531c0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 28.871094-5.449219 56.492188-15.363281 81.898438-6.152344-14.800782-17.09375-31.695313-33.007813-51.007813-18.179687-22.058594-36.113281-38.925781-36.867187-39.632813l-10.257813-9.613281-10.257812 9.613281c-.753906.707032-18.6875 17.574219-36.867188 39.632813-10.304687 12.503906-18.519531 23.992187-24.707031 34.558594-17.332031-10.367188-37.484375-16.203125-58.671875-16.203125-48.964844 0-92.457031 31.125-108.21875 77.457031l28.398438 9.664063c11.625-34.167969 43.703124-57.121094 79.820312-57.121094 16.957031 0 33.015625 5.070312 46.488281 13.976562-1.382812 5.25-2.082031 10.210938-2.082031 14.886719 0 42.949219 31.613281 78.648437 72.789062 85.066406-34.191406 20.820313-74.316406 32.824219-117.195312 32.824219-124.617188 0-226-101.382812-226-226zm356.503906 164.207031c-30.933594 0-56.097656-25.167969-56.097656-56.097656 0-20.832031 31.128906-59.441406 56.097656-84.90625 24.96875 25.464844 56.097656 64.074219 56.097656 84.90625 0 30.929687-25.167968 56.097656-56.097656 56.097656zm0 0" />
      </svg>
    ),
  },
  sad: {
    title: "Nemulțumit",
    activeColor: "#ff8b40",
    svg: ({ fill }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ fill }}
      >
        <path d="m165.609375 251.859375c28.269531 0 51.265625-22.996094 51.265625-51.261719 0-28.269531-22.996094-51.265625-51.265625-51.265625-28.265625 0-51.261719 22.996094-51.261719 51.265625 0 28.265625 22.996094 51.261719 51.261719 51.261719zm0-72.527344c11.726563 0 21.265625 9.539063 21.265625 21.265625 0 11.722656-9.539062 21.261719-21.265625 21.261719-11.722656 0-21.261719-9.539063-21.261719-21.261719 0-11.726562 9.539063-21.265625 21.261719-21.265625zm0 0" />
        <path d="m346.390625 149.332031c-28.269531 0-51.265625 22.996094-51.265625 51.265625 0 28.265625 22.996094 51.261719 51.265625 51.261719 28.265625 0 51.261719-22.996094 51.261719-51.261719 0-28.269531-22.996094-51.265625-51.261719-51.265625zm0 72.527344c-11.726563 0-21.265625-9.539063-21.265625-21.261719 0-11.726562 9.539062-21.265625 21.265625-21.265625 11.722656 0 21.261719 9.539063 21.261719 21.265625 0 11.722656-9.539063 21.261719-21.261719 21.261719zm0 0" />
        <path d="m341.011719 313.234375c-24.957031-17.636719-54.355469-26.957031-85.011719-26.957031s-60.054688 9.320312-85.011719 26.957031c-24.390625 17.234375-42.78125 41.078125-53.183593 68.953125l28.101562 10.492188c17.058594-45.699219 61.300781-76.402344 110.09375-76.402344 48.789062 0 93.03125 30.703125 110.089844 76.402344l28.105468-10.492188c-10.402343-27.875-28.792968-51.71875-53.183593-68.953125zm0 0" />
        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226 226-101.382812 226-226 226zm0 0" />
      </svg>
    ),
  },
  whatever: {
    title: "Nici multumit, nici nemulțumit",
    svg: ({ fill }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ fill }}
      >
        <path d="m165.609375 243.859375c28.269531 0 51.265625-22.996094 51.265625-51.261719 0-28.269531-22.996094-51.265625-51.265625-51.265625-28.265625 0-51.261719 22.996094-51.261719 51.265625 0 28.265625 22.996094 51.261719 51.261719 51.261719zm0-72.527344c11.726563 0 21.265625 9.539063 21.265625 21.265625 0 11.722656-9.539062 21.261719-21.265625 21.261719-11.722656 0-21.261719-9.539063-21.261719-21.261719 0-11.726562 9.539063-21.265625 21.261719-21.265625zm0 0" />
        <path d="m346.390625 141.332031c-28.269531 0-51.265625 22.996094-51.265625 51.265625 0 28.265625 22.996094 51.261719 51.265625 51.261719 28.265625 0 51.261719-22.996094 51.261719-51.261719 0-28.269531-22.996094-51.265625-51.261719-51.265625zm0 72.527344c-11.726563 0-21.265625-9.539063-21.265625-21.261719 0-11.726562 9.539062-21.265625 21.265625-21.265625 11.722656 0 21.261719 9.539063 21.261719 21.265625 0 11.722656-9.539063 21.261719-21.261719 21.261719zm0 0" />
        <path d="m128.507812 311h254.984376v30h-254.984376zm0 0" />
        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226 226-101.382812 226-226 226zm0 0" />
      </svg>
    ),
    activeColor: "#f6c83e",
  },
  satisfied: {
    title: "Mulțumit",
    activeColor: "#c7e33c",
    svg: ({ fill }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ fill }}
      >
        <path d="m256 384.808594c-51.761719 0-98.699219-32.574219-116.796875-81.054688l-28.105469 10.492188c10.910156 29.226562 30.195313 54.226562 55.769532 72.296875 26.167968 18.492187 56.988281 28.265625 89.132812 28.265625s62.96875-9.773438 89.136719-28.265625c25.574219-18.070313 44.855469-43.070313 55.765625-72.296875l-28.105469-10.492188c-18.097656 48.480469-65.035156 81.054688-116.796875 81.054688zm0 0" />
        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226 226-101.382812 226-226 226zm0 0" />
        <path d="m165.609375 251.859375c28.269531 0 51.265625-22.996094 51.265625-51.261719 0-28.269531-22.996094-51.265625-51.265625-51.265625-28.265625 0-51.261719 22.996094-51.261719 51.265625 0 28.265625 22.996094 51.261719 51.261719 51.261719zm0-72.527344c11.726563 0 21.265625 9.539063 21.265625 21.265625 0 11.722656-9.539062 21.261719-21.265625 21.261719-11.722656 0-21.261719-9.539063-21.261719-21.261719 0-11.726562 9.539063-21.265625 21.261719-21.265625zm0 0" />
        <path d="m346.390625 251.859375c28.265625 0 51.261719-22.996094 51.261719-51.261719 0-28.269531-22.996094-51.265625-51.261719-51.265625-28.269531 0-51.265625 22.996094-51.265625 51.265625 0 28.265625 22.996094 51.261719 51.265625 51.261719zm0-72.527344c11.722656 0 21.261719 9.539063 21.261719 21.265625 0 11.722656-9.539063 21.261719-21.261719 21.261719-11.726563 0-21.265625-9.539063-21.265625-21.261719 0-11.726562 9.539062-21.265625 21.265625-21.265625zm0 0" />
      </svg>
    ),
  },
  very_satisfied: {
    title: "Foarte mulțumit",
    activeColor: "#5ce65c",
    svg: ({ fill }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{fill}}
      >
        <path d="m234.003906 184.003906c7.066406-10.226562 10.0625-22.644531 8.441406-34.972656-3.597656-27.359375-28.777343-46.699219-56.148437-43.101562-10.238281 1.347656-19.6875 5.828124-27.15625 12.617187-8.972656-4.625-19.257813-6.515625-29.496094-5.167969-13.257812 1.742188-25.039062 8.542969-33.179687 19.148438-8.144532 10.605468-11.664063 23.746094-9.925782 37 1.621094 12.328125 7.730469 23.550781 17.1875 31.59375l74.625 63.5625zm-110.832031-5.730468c-3.792969-3.222657-6.238281-7.714844-6.886719-12.652344-.699218-5.3125.710938-10.578125 3.976563-14.824219 3.261719-4.25 7.980469-6.976563 13.292969-7.671875.867187-.113281 1.730468-.171875 2.597656-.171875 5.53125 0 10.890625 2.300781 14.714844 6.410156l14.351562 15.414063 9.878906-18.601563c3.042969-5.730469 8.691406-9.65625 15.109375-10.5 10.976563-1.441406 21.054688 6.304688 22.496094 17.269531.648437 4.9375-.550781 9.910157-3.386719 14.015626l-36.796875 53.347656zm0 0" />
        <path d="m413.082031 132.527344c-8.140625-10.605469-19.921875-17.40625-33.179687-19.148438-10.234375-1.34375-20.523438.542969-29.496094 5.171875-7.46875-6.789062-16.917969-11.273437-27.15625-12.621093-27.359375-3.589844-52.554688 15.738281-56.148438 43.101562-1.621093 12.328125 1.375 24.75 8.433594 34.960938l55.660156 80.695312 74.617188-63.558594c9.464844-8.050781 15.574219-19.273437 17.191406-31.597656 1.742188-13.257812-1.78125-26.398438-9.921875-37.003906zm-19.820312 33.09375c-.648438 4.9375-3.09375 9.429687-6.894531 12.664062l-49.339844 42.023438-36.804688-53.359375c-2.828125-4.09375-4.027344-9.066407-3.378906-14.003907 1.324219-10.082031 9.960938-17.441406 19.867188-17.441406.867187 0 1.746093.054688 2.628906.171875 6.417968.84375 12.066406 4.769531 15.109375 10.503907l9.878906 18.597656 14.351563-15.414063c4.421874-4.75 10.890624-7.078125 17.3125-6.238281 5.3125.695312 10.03125 3.421875 13.292968 7.671875 3.261719 4.246094 4.675782 9.511719 3.976563 14.824219zm0 0" />
        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226 226-101.382812 226-226 226zm0 0" />
        <path d="m106.289062 295.472656c0 82.550782 67.160157 149.710938 149.710938 149.710938s149.710938-67.160156 149.710938-149.710938v-15h-299.421876zm103.53125 110.4375c1.390626-2.730468 3.179688-5.75 5.4375-8.746094 9.667969-12.828124 23.375-19.335937 40.742188-19.335937 17.003906 0 30.511719 6.242187 40.144531 18.558594 2.546875 3.253906 4.523438 6.554687 6.039063 9.523437-14.21875 5.972656-29.824219 9.273438-46.183594 9.273438s-31.964844-3.304688-46.179688-9.273438zm164.953126-95.4375c-4.113282 32.738282-21.5 61.398438-46.578126 80.421875-1.992187-3.742187-4.457031-7.753906-7.492187-11.785156-10.753906-14.269531-30.710937-31.28125-64.703125-31.28125s-53.945312 17.011719-64.699219 31.28125c-3.039062 4.03125-5.5 8.042969-7.492187 11.789063-25.082032-19.023438-42.472656-47.6875-46.582032-80.425782zm0 0" />
      </svg>
    ),
  },
};

export function Emoticon({
  type = "satisfied",
  textStyle = {},
  active,
  onClick = () => {},
  color_disabled = "white",
  text_color
}) {
  const { title, activeColor, svg } = templates[type];
  const hoverClassName = createHoverClassName(activeColor);
  const SvgElement = svg({
    fill: active ? activeColor : text_color,
  });
  return (
    <div onClick={onClick} className={cx("emoticon-container", hoverClassName)}>
      <div className="emoticon-svg-container">{SvgElement}</div>
      <div style={textStyle} className="emoticon-title">
        {title}
      </div>
    </div>
  );
}
