// @flow
import * as React from "react";
import PromoCyrcle from "./../PromoIcon/PromoIcon";

import "./ProductItem.scss";
import Checkbox from "../Checkbox/Checkbox";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { SimpleDropdown } from "reusableComponents/SimpleDropdown/SimpleDropdown";

import PopupItemContainer from "./../PopupItemContainer/PopupItemContainer";
import { ReactComponent as CloseIcon } from "./../../assets/svg/close.svg";
import Button from "reusableComponents/Button/Button";
import $ from "jquery";

/**
 * @typedef {Object} IAssocItem
 * @property {String} assoc_name
 * @property {String} assoc_price
 * @property {String} assoc_tax
 * @property {String} category_id
 * @property {String} frond_end_unique
 * @property {String} id
 * @property {String} id_assoc
 * @property {String} id_product
 * @property {String} id_tax
 * @property {String} is_size
 * @property {Number} price
 * @property {String} product_name
 * @property {String} title
 */

/**
 * @typedef {Object} ICategory
 * @property {String} categ_name
 * @property {String} deleted
 * @property {String} id_product
 * @property {String} id_product_assoc_categ
 * @property {Number} max_selection
 * @property {Number} min_selection
 */

/**
 * @typedef {Object} IProduct
 * @property {String} active_for_order
 * @property {String} active_on_location
 * @property {Array} assoc
 * @property {String} assocCategoryInitialState_json
 * @property {Array} assocNoSize
 * @property {Number} assocSizeSelectedIndex
 * @property {Array} assocSizes
 * @property {Array} assocWithSize
 * @property {Array} assoc_categories
 * @property {String} category_id
 * @property {String} date_added
 * @property {String} deleted
 * @property {Object} description
 * @property {String} has_assoc
 * @property {String} has_delivery_rule
 * @property {String} id
 * @property {String} id_category
 * @property {String} id_product
 * @property {String} id_product_category
 * @property {String} id_tax
 * @property {String} id_venue
 * @property {Boolean} last_product
 * @property {String} last_update
 * @property {Null} pos_system_id
 * @property {Number} price
 * @property {String} product_description
 * @property {String} product_image
 * @property {String} product_name
 * @property {String} product_order
 * @property {String} product_package
 * @property {String} product_price
 * @property {String} product_status
 * @property {Null} product_stock
 * @property {String} product_weight
 * @property {String} restriction_date
 * @property {String} restriction_day
 * @property {String} title
 * @property {String} unique_assoc
 * @property {Boolean} use_category_assoc
 */

const default_image =
  "https://api.poloniq.ro/uploads/default_images/default_product.png";
/**
 *
 * @param {object} props
 * @param {IProduct} props.product
 */

export function ProductItem(props) {
  const {
    index,
    product,
    general_color,
    inner_text_color,
    disabled_color,
    command_cart_products,
    forbiden_interval,
    forbiden_interval_allow_btns,
    product_separator_color,
    command_categories,
    theme_text_style,
    products_have_image,
    id_venue,
    logo_venue,
    default_product_image,
    addNewProductsToCart,
    sizesSelectCss,
  } = props;
  const { assoc_group_label } = useSelector(({ builder }) => ({
    assoc_group_label:
      objectPath.get(builder, "preferences.assoc_group_label") || "Opțional:",
  }));
  const {
    id_product,
    title,
    price,
    description,
    product_weight,
    category_id,
    product_image,
    product_package,
    unique_assoc,
  } = product;

  const allAssoc = [...product.assocNoSize, ...product.assocWithSize];

  const p_cat_prop_name = "command_item_scroll_ref_" + category_id;
  const is_scroll_ref = product[p_cat_prop_name];
  const image_link =
    product_image || default_product_image
      ? `https://api.poloniq.ro/uploads/venues/${id_venue}/products/${product_image ||
          default_product_image}`
      : null;

  const [optionalsToAdd, setOptionalsToAdd] = React.useState({});
  const [categoryState, setCategoryState] = React.useState(null);

  function onCheckItem(frond_end_unique) {
    if (unique_assoc === "1" && !product.use_category_assoc) {
      if (optionalsToAdd[frond_end_unique]) {
        setOptionalsToAdd({});
      } else {
        setOptionalsToAdd({ [frond_end_unique]: true });
      }
    } else {
      if (optionalsToAdd[frond_end_unique]) {
        delete optionalsToAdd[frond_end_unique];
        setOptionalsToAdd({ ...optionalsToAdd });
      } else {
        setOptionalsToAdd({ ...optionalsToAdd, [frond_end_unique]: true });
      }
    }
  }
  //apply updates to App.js get_value_product_catalog() on 'all_assoc' as well
  function on_add_new_product_to_cart() {
    let sizeItem = {};
    if (
      product.assocSizeSelectedIndex >= 0 &&
      product.assocSizes.length >= 0 &&
      product.assocSizeSelectedIndex < product.assocSizes.length
    ) {
      sizeItem = product.assocSizes[product.assocSizeSelectedIndex];
    }

    const optionalProducts = Object.entries(optionalsToAdd).map(
      ([frond_end_unique]) => {
        const option = allAssoc.find(
          (item) => item.frond_end_unique === frond_end_unique
        );
        return {
          id_target: id_product,
          id_size: sizeItem.id_assoc,
          id: option.id_assoc,
          id_product: option.id_assoc,
          title: option.assoc_name,
          product_name: option.assoc_name,
          price: option.price,
          product_package: option.assoc_package,
          id_tax: option.assoc_tax,
          id_size: option.id_size,
          id_product_assoc_categ: option.id_product_assoc_categ,
        };
      }
    );

    const productCopy = JSON.parse(JSON.stringify(product));
    delete productCopy.assocSizes;
    delete productCopy.assocSizeSelectedIndex;
    delete productCopy.allAssoc;
    delete productCopy.command_item_scroll_ref_31;
    delete productCopy.assocNoSize;
    delete productCopy.assocWithSize;
    delete productCopy.assoc;
    const finalItems = [
      {
        ...productCopy,
        id_target: undefined,
        id_size: sizeItem.id_assoc,
        sizeItem: {
          id_assoc: sizeItem.id_assoc,
          assoc_name: sizeItem.assoc_name,
          assoc_tax: sizeItem.assoc_tax,
          assoc_price: sizeItem.assoc_price,
          is_size: sizeItem.is_size,
        },
      },
      ...optionalProducts,
    ];
    /**
     * if we use the category => validate min, max selection
     */
    if (product.use_category_assoc) {
      const errorPerCategory = {};
      let scrollToId = undefined;
      Object.entries(categoryState.categories).forEach(([id, content]) => {
        const numberOfItems = finalItems.filter(
          (_item) => _item.id_product_assoc_categ === id
        ).length;
        const errorItem = {};
        if (
          content.min_selection > 0 &&
          numberOfItems < content.min_selection
        ) {
          errorItem.min_selection = true;
        }
        if (
          content.max_selection > 0 &&
          numberOfItems > content.max_selection
        ) {
          errorItem.max_selection = true;
        }
        if (Object.keys(errorItem).length > 0) {
          errorPerCategory[id] = errorItem;
          if (!scrollToId) {
            scrollToId = id;
          }
        }
      });

      if (Object.entries(errorPerCategory).length > 0) {
        // alert(JSON.stringify({ errorPerCategory }));

        setCategoryState({
          ...categoryState,
          errorPerCategory,
        });

        // const elementNode = document.querySelector(
        //   `[category-item-id="${scrollToId}"]`
        // );
        // const containerNode = document.querySelector(
        //   ".popup-container-item-portal"
        // );

        // if (elementNode) {
        //   let offset = 0;
        //   const elements = document.querySelectorAll(`[use-for-height="true"]`);
        //   const categoryNodes = document.querySelectorAll(
        //     `[category-item="true"]`
        //   );

        //   for (let i = 0; i < categoryNodes.length; i++) {
        //     if (categoryNodes[i].getAttribute("category-item-id") === String(scrollToId)) {
        //       break;
        //     }
        //     offset += categoryNodes[i].scrollHeight;
        //   }
        //   for (let i = 0; i < elements.length; i++) {
        //     offset += elements[i].scrollHeight;
        //   }

        //   console.log(offset);

        //   $([containerNode]).animate(
        //     {
        //       scrollTop: offset,
        //     },
        //     1000
        //   );
        // }

        return;
      }
      closeCategoryAssocPopup();
    }

    addNewProductsToCart(finalItems);
    setOptionalsToAdd({});
    productCategorySetup();
  }

  function openCategoryAssocPopup() {
    setCategoryState({
      ...categoryState,
      popupOpened: true,
    });
    props.set_scroll_lock(true);
  }
  function closeCategoryAssocPopup() {
    setCategoryState({
      ...categoryState,
      popupOpened: false,
    });
    props.set_scroll_lock(false);
  }

  /**
   *
   * @param {IAssocItem} assocItem
   */
  function computeAssocItemLabel(assocItem) {
    return `${assocItem.assoc_name} (+${assocItem.assoc_price} lei)`;
  }

  /**
   * assigns the default categori
   */
  function productCategorySetup() {
    if (product.use_category_assoc === true) {
      let assocCategoryInitialState = null;
      try {
        assocCategoryInitialState = JSON.parse(
          product.assocCategoryInitialState_json
        );
        if (
          !assocCategoryInitialState.categories ||
          typeof assocCategoryInitialState.categories !== "object"
        ) {
          throw { m: "wrong data", d: assocCategoryInitialState };
        }
      } catch (err) {
        console.log(
          "Error while parsing category for this product" + product.id_product,
          err
        );
      }
      if (assocCategoryInitialState) {
        setCategoryState(assocCategoryInitialState);
      }
    }
  }

  React.useEffect(
    () => {
      productCategorySetup();
    },
    [product.assocCategoryInitialState]
  );

  return (
    <React.Fragment>
      {is_scroll_ref === 1 && (
        <div
          className={
            "command_title_category user_select_none navs_bar_height_as_margin_top"
          }
          id={p_cat_prop_name}
          category-node-ref="true"
          style={{ color: general_color }}
        >
          {(command_categories.find((item) => item.id === category_id) || {})
            .name || ""}
        </div>
      )}
      <div
        className={`command_menu_list_item_column -product-item-container${
          products_have_image ? " img_type" : ""
        }`}
        last_product={String(Boolean(product.last_product))}
      >
        <div
          key={"product_col_catalog" + title + "_" + index}
          className="command_menu_list_item_container"
        >
          <div
            className="command_menu_list_item_title"
            style={{
              color: `${general_color}`,
            }}
          >
            {product.old_price && (
              <PromoCyrcle
                color={inner_text_color}
                backgroundColor={general_color}
              />
            )}
            {title}
          </div>
          {products_have_image && (
            <div
              className="product_image_container"
              style={{ width: "100%", display: "inline-block" }}
            >
              {image_link && <img className="obj_fit_img" src={image_link} />}
              {!image_link && (
                <div
                  className="fallback-product-image"
                  style={{
                    backgroundColor: product_separator_color,
                  }}
                >
                  <img className="logo_restaurant_cm" src={logo_venue} />
                </div>
              )}
            </div>
          )}

          <div
            style={{ ...theme_text_style }}
            className="command_menu_list_item_desc"
          >
            {/* eslint-disable */ }
            <React.Fragment> {description}</React.Fragment>
            {product_weight ? (
              <p>
                {/* &#160;&#160;{`|`}&#160;&#160; */}
                {`${product_weight}`}
              </p>
            ) : (
              ""
            )}
          </div>
          {Boolean(Number(product_package)) && (
            <div
              style={{ ...theme_text_style }}
              className="command_menu_list_item_desc -product-ambalaj-"
            >
              Ambalaj +{product_package} lei
            </div>
          )}

          {/* ASSOC SIZE SELECTOR */}
          {Array.isArray(product.assocSizes) && product.assocSizes.length > 0 && (
            <div
              style={{ ...theme_text_style }}
              className="command_menu_list_item_desc command-menu-list-item-optional "
            >
              <div> Alege dimensiune: </div>
              <div className="product-optional-list-cont">
                <SimpleDropdown
                  showError={false}
                  withArrow={true}
                  options={product.assocSizes.map((sizeItem, index) => ({
                    assocItem: sizeItem,
                    assocIndex: index,
                    value: sizeItem.id_assoc,
                    label: sizeItem.assoc_name,
                    key: `${sizeItem.id_assoc}-allAssoc-size-option-${index}`,
                  }))}
                  customCssStyle={sizesSelectCss}
                  selectOption={(option) => {
                    props.onChangeSizeItem(option);
                  }}
                  selected={{
                    value:
                      product.assocSizes?.[product.assocSizeSelectedIndex]
                        ?.id_assoc,
                    label:
                      product.assocSizes?.[product.assocSizeSelectedIndex]
                        ?.assoc_name,
                  }}
                />
              </div>
            </div>
          )}

          {/* ALL ASSOC CHECKBOXES */}
          {!product.use_category_assoc &&
            Array.isArray(allAssoc) &&
            allAssoc.length > 0 && (
              <>
                <div
                  style={{ ...theme_text_style }}
                  className="command_menu_list_item_desc command-menu-list-item-optional "
                >
                  <div> {assoc_group_label} </div>
                  <div className="product-optional-list-cont">
                    {allAssoc.map((assocItem, index) => {
                      const {
                        id_assoc,
                        assoc_name,
                        assoc_price,
                        id_size,
                      } = assocItem;
                      return (
                        <Checkbox
                          key={`checkbox_optional_associated_${id_assoc}_item_${index}`}
                          onClick={() => {
                            onCheckItem(assocItem.frond_end_unique);
                          }}
                          label={computeAssocItemLabel(assocItem)}
                          label_style={{ ...theme_text_style }}
                          borderColor={general_color}
                          containerClassName={"asap_checkbox_cont"}
                          checked={optionalsToAdd[assocItem.frond_end_unique]}
                          svgColor={inner_text_color}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            )}

          <div className="command_menu_list_item_actions_cont">
            {product.old_price && (
              <div
                style={{
                  color: `${general_color}`,
                }}
                className="command_menu_list_item_price cutted_old_price___"
              >
                {product.old_price} lei
              </div>
            )}
            <div
              style={{
                color: `${general_color}`,
              }}
              className="command_menu_list_item_price"
            >
              {price} lei
            </div>
            <div
              onClick={(event) => {
                if (product.use_category_assoc) {
                  openCategoryAssocPopup();
                } else {
                  on_add_new_product_to_cart();
                }
              }}
              style={{
                borderColor:
                  forbiden_interval && !forbiden_interval_allow_btns
                    ? disabled_color
                    : general_color,
              }}
              className={`command_menu_list_item_button${
                forbiden_interval && !forbiden_interval_allow_btns
                  ? " disabled_button_"
                  : " fill_anim"
              }${
                Object.entries(command_cart_products).some(
                  ([id_bundle, bundle]) =>
                    Object.entries(bundle).some(
                      ([id_product_bundle]) =>
                        id_product_bundle === product.id_product
                    )
                )
                  ? " no_border_radius_mobile_"
                  : ""
              }`}
            >
              <div className="command_btn_close_cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 131 131"
                  style={{
                    fill:
                      forbiden_interval && !forbiden_interval_allow_btns
                        ? disabled_color
                        : general_color,
                  }}
                >
                  <defs />
                  <g data-name="Layer 2">
                    <g data-name="Layer 1">
                      <polygon points="57 0 74.09 0 73.98 56.97 131 57 131 74.01 73.81 74.01 74 131 57 131 57 74 0 73.83 0 56.81 56.99 57 57 0" />
                    </g>
                  </g>
                </svg>
              </div>

              {/* ADD / SELECT BUTTON */}
              {!product.use_category_assoc && (
                <a
                  style={{ ...theme_text_style }}
                  className="command_menu_list_item_sub_button"
                >
                  Adaugă în coș
                </a>
              )}
              {product.use_category_assoc && (
                <a
                  style={{ ...theme_text_style }}
                  className="command_menu_list_item_sub_button"
                >
                  Selecteaza
                </a>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: product_separator_color,
          }}
          className="section_divider _abs_bot_lf"
        />
      </div>
      {categoryState && categoryState.popupOpened && (
        <PopupItemContainer customCssStyle={props.customCssStyle}>
          <div className="assoc-category-popup-main-wrapper">
            <div
              className="flex-all cursor-pointer position-absolute absolute-top absolute-right assoc-close-btn"
              onClick={() => closeCategoryAssocPopup()}
            >
              <CloseIcon />
            </div>
            <div className="assoc-category-popup-inner">
              <div style={{ widht: "100%" }} use-for-height="true">
                <div className="assoc-category-popup-image-wrapper">
                  <img src={image_link} alt={""} />
                </div>
                <div className="assoc-category-popup-title">{title}</div>
                <div className="assoc-category-popup-price">
                  {Number(price) === 1 ? "1 leu" : `${price} lei`}
                </div>
                <div className="assoc-category-popup-description">
                  {" "}
                  {description}
                </div>
              </div>
              {Object.entries(categoryState.categories).map(([id, content]) => {
                const thisCategoryAssocItems = allAssoc.filter(
                  (item) => item.id_product_assoc_categ === id
                );

                let restrictionText = "Alege produse";
                if (
                  content.min_selection &&
                  content.min_selection === content.max_selection
                ) {
                  restrictionText = `Alege exact ${
                    content.max_selection === 1
                      ? "1 produs"
                      : `${content.max_selection} produse`
                  }`;
                } else {
                  if (content.min_selection && content.max_selection) {
                    restrictionText = `Alege minim ${
                      content.min_selection === 1
                        ? "1 produs"
                        : `${content.min_selection} produse`
                    } (maxim ${content.max_selection})`;
                  } else {
                    if (content.min_selection) {
                      restrictionText = `Alege ${
                        content.min_selection === 1
                          ? "1 produs"
                          : `${content.min_selection} produse`
                      }`;
                    }
                    if (content.max_selection) {
                      restrictionText = `Alege produse (maxim ${
                        content.max_selection === 1
                          ? "1 produs"
                          : `${content.max_selection}`
                      })`;
                    }
                  }
                }

                return (
                  <div
                    category-item="true"
                    category-item-id={id}
                    className="assoc-category-popup-category-item"
                  >
                    <div className={`assoc-category-popup-category-title`}>
                      {content.categ_name}{" "}
                      <span className="assoc-category-popup-category-title-info">
                        {content.min_selection || content.max_selection
                          ? `(obligatoriu)`
                          : `(opțional)`}
                      </span>
                    </div>
                    <div
                      className={`assoc-category-popup-category-desc${
                        categoryState.errorPerCategory &&
                        categoryState.errorPerCategory[id]
                          ? " error_text"
                          : ""
                      }`}
                    >
                      {restrictionText}
                    </div>
                    {thisCategoryAssocItems.map(
                      /**
                       *
                       * @param {IAssocItem} item
                       */
                      (item) => {
                        return (
                          <div className="assoc-category-popup-category-check">
                            <Checkbox
                              key={"checkbox_anticipation"}
                              onClick={(event) => {
                                onCheckItem(item.frond_end_unique);
                              }}
                              checked={Boolean(
                                optionalsToAdd[item.frond_end_unique]
                              )}
                              // containerClassName={"asap_checkbox_cont"}
                              // labelClassName={"asap_checkbox_label"}
                              // squareClassName={"asap_square_checkbox"}
                              label={computeAssocItemLabel(item)}
                              label_style={{ ...theme_text_style }}
                              borderColor={general_color}
                              svgColor={inner_text_color}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
              <div className="flex-all assoc-category-popup-add-btn">
                <div className="assoc-category-popup-error">
                  {categoryState && categoryState.errorPerCategory && (
                    <div>Te rugam sa alegi produsele extra obligatorii</div>
                  )}
                </div>

                <Button
                  backgroundColor={general_color}
                  color={inner_text_color}
                  borderColor={product_separator_color}
                  onClick={(event) => {
                    on_add_new_product_to_cart();
                  }}
                  text={"Adaugă la comanda ta"}
                />
              </div>
            </div>
          </div>
        </PopupItemContainer>
      )}
    </React.Fragment>
  );
}
