import React, { useEffect } from "react";
import {  CSSTransition } from "react-transition-group";

import "./AnimationItem.scss";

export default function AnimationItem({ unmountElement, markedForDeleting, classNames, timeout, children }) {
  const [show, setShow] = React.useState(true);
  useEffect(() => {
    if(markedForDeleting) {
      setShow(false);
      setTimeout(() => {
        if(unmountElement) unmountElement();
      }, timeout || 150);
    }
  }, markedForDeleting)
  return (
    <CSSTransition
      timeout={timeout || 150}
      
      in={Boolean(show)}
    
      classNames={classNames}
    >
      {children}
    </CSSTransition>
  );
}