// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";

import * as helperFunctions from "utils/helper_functions";
import { actions } from "store/reducers/builderReducer";
import { cx, css } from "emotion";

import $ from "jquery";

import { menuActions } from "./menuActions";

export default function ProductCategoryPage({
  productNameValue,
  onModalOpen,
  stopScroll,
  show,
}) {
  const dispatch = useDispatch();
  const {
    products,
    menuPageConfigs,
    modalIsOpened,
    cartProductsOpen,
    cartProductsIndexing,
    pageTransitionTime,

    id_category,
    menuProductCategories,
    menuConfigGlobalUpdateKey,
    digital_menu_use_item_description,
  } = useSelector(
    ({
      builder: { menuConfig, menuProductCategories, htmlService, preferences },
    }) => ({
      products:
        objectPath.get(menuConfig, "menuCurrentPage") !== "product_category"
          ? menuProductCategories.reduce(
              (previousValue, category) => [
                ...previousValue,
                ...category.products,
              ],
              []
            )
          : objectPath.get(
              menuConfig,
              "menuPageConfigs.product_category.categorySelected.products"
            ) || [],
      id_category: objectPath.get(
        menuConfig,
        "menuPageConfigs.product_category.categorySelected.id_category"
      ),
      menuProductCategories: menuProductCategories || [],
      menuPageConfigs: objectPath.get(menuConfig, "menuPageConfigs"),
      cartProductsOpen: objectPath.get(menuConfig, "cartProductsOpen"),
      cartProductsIndexing:
        objectPath.get(menuConfig, "cartProductsIndexing") || {},
      pageTransitionTime: htmlService.pageTransitionTime || 0,
      menuConfigGlobalUpdateKey: objectPath.get(
        menuConfig,
        "menuPageConfigs.menuConfigGlobalUpdateKey"
      ),
      digital_menu_use_item_description:
        objectPath.get(preferences, "digital_menu_use_item_description") ===
        "1",
    })
  );
  const [productsFiltered, setProductsFiltered] = React.useState([]);

  function onOpenProductModal(product) {
    const {
      id_product,
      product_description,
      product_price,
      product_name,
      product_image,
    } = product;
    dispatch(
      actions.setMenuPageConfigs({
        menuPageConfigs: {
          ...menuPageConfigs,
          product_category: {
            ...menuPageConfigs.product_category,
            productModal: {
              id_product,
              product_description,
              product_price,
              product_name,
              product_image,
              ...product,
            },
          },
        },
      })
    );
    onModalOpen();
  }

  React.useEffect(
    () => {
      if (!productNameValue) {
        setProductsFiltered(null);
      } else {
        const searchProductNamePrepared = helperFunctions.prepareStringForRawComparison(
          productNameValue
        );

        setProductsFiltered(
          products.filter(({ product_name }) => {
            return (
              helperFunctions
                .prepareStringForRawComparison(product_name)
                .indexOf(searchProductNamePrepared) > -1
            );
          })
        );
      }
    },
    [productNameValue]
  );
  const [displayNode, setDisplayNode] = React.useState();
  React.useEffect(
    () => {
      if (!show) {
        setTimeout(() => {
          helperFunctions.lockMenuBodyContainer();
          // document.body.style.overflow = "";
          // document.body.style.position = "";
          // document.body.style.height = "";
          setDisplayNode(false);
        }, pageTransitionTime);
      } else {
        setDisplayNode(true);
      }
    },
    [show]
  );
  React.useEffect(
    () => {
      if (displayNode) {
        $("#menu-layout-container").scrollTop(0); //###scroll point
      }
    },
    [displayNode]
  );

  //when refreshes page (this goes after menuconfig has been assigned with products and other)
  React.useEffect(
    () => {
      if (
        !id_category &&
        Array.isArray(menuProductCategories) &&
        menuProductCategories.length > 0
      ) {
        const link_category_ = window.location.href.split(
          "/meniu/product_category/"
        );
        const id_category_from_link =
          link_category_.length > 1 ? link_category_[0] : undefined;
        let indexCategory = 0;
        if (id_category_from_link) {
          indexCategory = menuProductCategories.findIndex(
            (item) => item.id_category === id_category_from_link
          );
          if (indexCategory < 0) {
            indexCategory = 0;
          }
        }
        menuActions(dispatch).selectCategoryProducts(
          menuProductCategories[indexCategory],
          menuPageConfigs
        );
      }
    },
    [menuConfigGlobalUpdateKey]
  );
  React.useEffect(() => {
    return () => {
      menuActions(dispatch).closeProductModal(menuPageConfigs);
      menuActions(dispatch).closeCartModal();
      menuActions(dispatch).removeInputPlaceholder(menuPageConfigs);
    };
  }, []);
  return !displayNode ? (
    ""
  ) : (
    <div
      className={cx(
        `products cut-first-el-padding-products${
          show
            ? " menu-page-fade-in-anim-class"
            : " menu-page-fade-out-anim-class"
        }`,
        css`
          animation-duration: ${pageTransitionTime / 1000}s;
        `
      )}
      style={stopScroll ? stopScroll.style : {}}
      id="product-category-page-section-menu-id"
    >
      {(Array.isArray(productsFiltered)
        ? productsFiltered
        : products || []
      ).map((item, index) => (
        <div
          key={`${item.id_product}-search-item-list-product-${index}`}
          className={`product-inner${
            cartProductsIndexing[item.id_product] ? " menu-product-checked" : ""
          }`}
          onClick={() => onOpenProductModal({ ...item })}
        >
          <div className="product-inner-left">
            <div className="product-name jost">{item.product_name}</div>
            {item.product_weight && (
              <div className="product-weight poppins">
                <div>{item.product_weight}</div>
              </div>
            )}
          </div>
          
          <div className="product-price poppins">
            {item.product_price || 0}
            {(Number(item.product_price) || 0) === 1 ? " leu" : " lei"}
          </div>
          <div className="product-add-btn">
            <i className="fa fa-plus plus-btn" />
            <i className="fa fa-check check-btn" />
          </div>

          {digital_menu_use_item_description && (
            <div className="product-name product-hands-on-description">
              {" "}
              {item.product_description}{" "}
            </div>
          )}
        </div>
      ))}
      {Array.isArray(productsFiltered) && productsFiltered.length === 0 && (
        <div className="no-result-message product-name jost">
          {`Niciun rezultat pentru '${productNameValue}'.`}
        </div>
      )}
    </div>
  );
}
