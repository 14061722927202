// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import "./MenuCheckBoxItem.scss";

export default function MenuCheckBoxItem({onChange, value, label}) {
  return (
    <div className="checkbox-menu-container" onClick={onChange}>
        <div className="checkbox-menu-box">{value && <FontAwesomeIcon icon={faCheck} color="black" className="svg-check-menu-item" />}</div>
        <div className="checkbox-menu-label">{label}</div>
    </div>
  );
};