// @flow
import * as React from "react";
import "./Popup.css";

export function ContentPlainText({
  color,
  text
}) {
  return (
    <div
      style={{ color: color }}
      className="optional_mesasge_popup"
    >
      {text || ""}
    </div>
  );
}
export default ContentPlainText;