// @flow
import * as React from 'react';

export function Frame({childnode,BgStyle, general_color, on_close_generic_modal, no_close_btn, children, className}) {
  return (
    <div 
    style={BgStyle? {...BgStyle} : {}}
    
    className={"_payment_modal pop_up__" + (className ? " "+className : "")}>
    <div
      style={{ borderColor: general_color }}
      className="payment_conf_modal_container command_receipt_forbiden_container pop_up__"
    >
      {!no_close_btn && <div className="pop_up_close_btn">
        <svg
          onClick={on_close_generic_modal}
          style={{ fill: general_color }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 104.79 104.65"
        >
          <g>
            <g>
              <polygon points="0.06 12.11 12.13 0.01 52.38 40.34 92.68 0 104.72 12.02 64.32 52.49 104.79 92.62 92.78 104.65 52.43 64.39 12.05 104.61 0 92.58 40.39 52.38 0.06 12.11" />
            </g>
          </g>
        </svg>
      </div>}
      <React.Fragment> {children} </React.Fragment>
    </div>
    </div>
  );
};
export default Frame;