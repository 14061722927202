import { actions } from "./../reducers/builderReducer";
import axios from "axios";
import * as utilFunctions from "utils/helper_functions";
import { loaderActions } from "./loaderActions";

export function menuOrderActions(dispatch) {
  return {
    submitMenuOrder: (order, cartProduct) => {
      return new Promise((resolve, reject) => {
        const {
          id_venue,
          id_table,
          id_venue_location,
          id_reservation
        } = order;
        const id_client = utilFunctions.get_info_parsed_l_st_venue(
          id_venue,
          "clients"
        );
        const menu_order_lines = cartProduct.map((product) => ({
            ...product,
            product_qty: product.quantity
        }));
        const payload = {
          sumbmit_menu_order: 1,
          id_venue,
          id_table,
          id_venue_location,
          id_client,
          id_reservation,
          menu_order_lines,
        };
        loaderActions(dispatch).openLayoutLoader();
        axios
          .post("api", payload)
          .then(({ data }) => {
            loaderActions(dispatch).closeLayoutLoader();
            
            resolve(data);
          })
          .catch((err) => {
            loaderActions(dispatch).closeLayoutLoader();
            reject(err);
            console.log(err, "error while sending order menu lines");
          });
      });
    },
  };
}
