import React, { Component } from "react";

import "./Dropdown.css";
import { ClickListener } from "./ClickListener";
import classNames from "classcat";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { cx } from "emotion";

class Dropdown extends React.Component {
  static defaultProps = {
    allowOpen: true,
    allowClose: true,
    disabled: false,
    required: false,
    onSelect: () => {},
    clear: () => {},
    label: "",
  };

  generateDropdownStyle = () => {
    return {
      label: {},
      container: {
        maxWidth: "1000px",
        width: "100%",
        zIndex: this.props.isOpen ? "99999" : "1",
      },
      options: {},
    };
  };
  componentDidMount = () => {};
  handleClickOutside = (event) => {
    this.props.isOpen && this.props.close();
  };

  generateClassNames = () => {
    return {
      optionsContainer: classNames([
        "Dropdown-optionsContainer" +
          (this.props.innerContainerClassName
            ? " " + this.props.innerContainerClassName
            : ""),
        { isOpen: this.props.isOpen },
      ]),
      icon: classNames(["iconx material-icons", { open: this.props.isOpen }]),
    };
  };

  handleClickInside = (event) => {
    if (event.target.matches(".iconx")) {
      return this.props.toggle();
    }

    !this.props.isOpen && this.props.open();
  };

  handleClear = (event) => {
    if (event.key === "Backspace") {
      this.props.clear();
    }
  };

  render() {
    const { props, state } = this;
    const styles = this.generateDropdownStyle();
    const classes = this.generateClassNames();

    return (
      <ClickListener
        parent_el_classname={props.parent_el_classname}
        watchingOutside={props.isOpen}
        onClickOutside={this.handleClickOutside}
        onClickInside={this.handleClickInside}
        style={{
          ...styles.container,
          ...(props.position || {}),
          ...(props.additionalStyleContainer
            ? props.additionalStyleContainer
            : {}),
        }}
        className={
          `Dropdown${
            props.containerClassName ? " " + props.containerClassName : ""
          }`
        }
      >
        <div className="Dropdown-innerContainer">
          {/* <input
            value={props.Value}
            className="Dropdown-input"
            placeholder={props.placeholder}
            onKeyUp={this.handleClear}
            spellCheck={false}
            // onChange={props.onChange}
          /> */}
          {props.isOpen && (
            <DropdownOptionsList
            scroll_bar_color={props.scroll_bar_color}
              hover_color={props.hover_color}
              optionClassName={props.optionClassName}
              onOptionClick={props.onSelect}
              options={props.options}
              classNames={classes}
              styles={{
                ...styles,
                options: {
                  ...(styles.options ? styles.options : {}),
                  ...(props.additionalStyleInnerCont
                    ? props.additionalStyleInnerCont
                    : {}),
                },
              }}
            />
          )}
        </div>
      </ClickListener>
    );
  }
}

const DropdownLabel = (props) => {
  return (
    <label
      className={"Dropdown-label" + (props.required ? " required" : "")}
      style={props.style}
    >
      {props.label}
    </label>
  );
};

const DropdownOptionsList = (props) => {
  return (
    <div
      style={props.styles.options}
      className={
        
        
      cx(
        props.classNames.optionsContainer,
        css`
          &::-webkit-scrollbar-thumb {
            background-color: ${props.scroll_bar_color}!important;
          }
        `
      )}
    >
      {props.options.map((option, index) => (
        <div
          key={option.Value + "_dropdown_item_" + index}
          className={`Dropdown-option${
            props.optionClassName ? " " + props.optionClassName : ""
          }`}
          css={css`
            &:hover {
              color: ${props.hover_color || "unset"};
            }
          `}
          onClick={(event) => {
            if (
              option.is_datepicker &&
              event.currentTarget.contains(event.target)
            ) {
              let day_calendar_clicked = false;
              let thisTag = event.target;
              while (
                thisTag.parentNode &&
                thisTag !== event.currentTarget &&
                thisTag.parentNode.tagName !== "BODY"
              ) {
                if (thisTag.classList.contains("react-datepicker__week")) {
                  day_calendar_clicked = true;
                  break;
                }
                thisTag = thisTag.parentNode;
              }
              if (day_calendar_clicked) {
                props.onOptionClick(event, option);
              }
            } else {
              props.onOptionClick(event, option);
            }
          }}
        >
          {option.additionalNode && <React.Fragment>{option.additionalNode} </React.Fragment>}
          {option.displayValue || option.Value}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
