// @flow
import * as React from "react";
import "./Popup.css";

export function ContentProgram({command_time_program, color, secondary_color}) {
  return (
    <React.Fragment>
      <div
        style={{ color: color }}
        className="popup_program_title__"
      >
        Programul pentru
        <span className="mob_only__"> livrări este: </span>
        <span className="desk_only__">
          <br></br>livrări este:{" "}
        </span>
      </div>
      {command_time_program.map(
        ({ day_st, day_end, h_st, h_end }, index) => {
            
            return(
          <div
            className="popup_program_list_item__"
            key={"progr__" + index}
          >
            <div style={{
                 ...(color ? {color} : {})
            }}>
              {day_st}{`${day_end ? " - "+day_end : ""}`}
            </div>
            <div
              style={secondary_color ? {color: secondary_color} : {}}
            >
              {h_st} - {h_end}
            </div>
          </div>
        )}
      )}
    </React.Fragment>
  );
}
export default ContentProgram;
