import React, { useEffect } from "react";

import Checkbox from "reusableComponents/Checkbox/Checkbox";

import "./PaymentTypeForm.scss";

import {
  paymentTypeNames, delivery_type_names
} from "utils/constants";

export default function PaymentTypeForm({
  general_color,
  product_separator_color,
  inner_text_color,
  theme_text_style,

  paymentTypes,
  id_delivery_type,
  onSelectPaymentType,
  selected
}) {
  return (
    <div className="payment-form-container">
      <div
        style={{
          backgroundColor: product_separator_color,
        }}
        className={`section_divider`}
      />
      <div className="payment-form-percentages">
        {paymentTypes.map((payment_type, index) => (
          <Checkbox
            key={`payment_type_item_form_checkbox_${index}`}
            onClick={(event) => {
              onSelectPaymentType(payment_type);
            }}
            containerClassName={""}
            labelClassName={"text-tip"}
            squareClassName={""}
            label={paymentTypeNames[payment_type][id_delivery_type] || paymentTypeNames[payment_type]["defualt"]}
            label_style={{ ...theme_text_style }}
            borderColor={general_color}
            checked={ payment_type === selected}
            svgColor={inner_text_color}
          />
        ))}
      </div>
    </div>
  );
}