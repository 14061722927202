// @flow
import * as React from "react";
import Button from "reusableComponents/Button/Button";
import "./CookiesRequest.scss";

export function CookiesRequest({
  general_color,
  color,
  backgroundColor,
  containerClassName,
  cookiesTextContainer,
  onClick,
  labelTextColor,
  borderColor, 
  cookiesTermsLink
}) {
  return (
    <div
      className={`cookies_container${
        containerClassName ? " " + containerClassName : ""
      }`}
      style={{backgroundColor}}
    >

        <div
          className={`cookies_text${
            cookiesTextContainer ? " " + cookiesTextContainer : ""
          }`}
          style={{color: labelTextColor}}
        >
          Acest site folosește cookies. Continuarea navigării presupune că ești de acord cu utilizarea cookie-urilor. <a href={cookiesTermsLink} target='_blank'>Află mai multe detalii</a>
          </div>
        <div className={`cookies_button_cont`}>
          <Button
            color={color}
            borderColor={borderColor}
            containerClassName="cookies_button"
            backgroundColor={general_color}
            text="Am înțeles"
            onClick={onClick}
          />
        </div>
      
    </div>
  );
}
export default CookiesRequest;
