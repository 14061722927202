// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { actions } from "store/reducers/builderReducer";
import { cx, css } from "emotion";
import $ from 'jquery';
import { MenuRootContext } from "./MenuRootContext";

import {menuActions} from "./menuActions";

export default function CategoryPage({show}) {
  const dispatch = useDispatch();
  const { pageConfig, menuProductCategories, menuPageConfigs, pageTransitionTime, categoryPreviousScrollTop, id_venue } = useSelector(
    ({ builder }) => ({
      pageConfig:
        objectPath.get(builder, "menuConfig.pageConfigs.category") || {},
      menuProductCategories:
        objectPath.get(builder, "menuProductCategories") || [],
      menuPageConfigs:
        objectPath.get(builder, "menuConfig.menuPageConfigs") || {},
      pageTransitionTime: objectPath.get(builder, "htmlService.pageTransitionTime") || 0,
      categoryPreviousScrollTop: objectPath.get(builder, "htmlService.categoryPreviousScrollTop") || 0,
      id_venue: objectPath.get(builder, "preferences.id_venue")
    })
  );
    
  const valueContext = React.useContext(MenuRootContext);
  const history = objectPath.get(valueContext, "history") || {};

  function navigateToCategoryProduct(category) {
    const scrollTop = $("#menu-layout-container").scrollTop(); //###scroll point
    dispatch(actions.setCategoryPreviousScrollTop({categoryPreviousScrollTop: scrollTop}));
    menuActions(dispatch).selectCategoryProducts(category, menuPageConfigs);
    history.push('/meniu/product_category/' + category.id_category);
  }

  const [displayNode, setDisplayNode] = React.useState();
  React.useEffect(
    () => {
      if (!show) {
        setTimeout(() => {
          setDisplayNode(false);
        }, pageTransitionTime);
        
      } else {
        setDisplayNode(true);
      }
    },
    [show]
  );
  React.useEffect(() => {
    if(displayNode) {
      $("#menu-layout-container").scrollTop(categoryPreviousScrollTop); //###scroll point
      dispatch(actions.setCategoryPreviousScrollTop({categoryPreviousScrollTop: 0}));
    }
  }, [displayNode]);

  React.useEffect(() => {
    return () => {
      menuActions(dispatch).closeCartModal();
    }
  }, [])
  
  return !displayNode ? "" : (
    <div className={cx(
      `categories cut-first-el-padding${
        show
          ? " menu-page-fade-in-anim-class"
          : " menu-page-fade-out-anim-class"
      }`,
      css`
        animation-duration: ${pageTransitionTime / 1000}s;
      `
    )}>
      {menuProductCategories.map((category, index) => (
        <div
          key={`${category.id_category}-category-item-menu-${index}`}
          className="left-category"
          onClick={() => navigateToCategoryProduct(category)}
        >
          <div
            className="category-image"
            style={{
              backgroundImage: 
              category.category_image ?
              `url('https://api.poloniq.ro/uploads/venues/${id_venue}/categories/${
                category.category_image
              }')` :
              `url('https://api.poloniq.ro/uploads/venues/${id_venue}/categories/default_image.png')`
              ,
            }}
          />
          <div className="category-title jost">{category.category_name}</div>
          <div className="category-number jost">
            {" "}
            {category.products.length}{" "}
            {category.products.length === 1 ? "produs" : "produse"}{" "}
          </div>
        </div>
      ))}
    </div>
  );
}
