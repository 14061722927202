import "./MenuButton.scss";
import React from "react";

export default function MenuButton({ label, onClick }) {
  return (
    <div className="menu-btn-container-item" onClick={onClick}>
      <div className="intro-btn-text poppins">{label}</div>
      <div className="intro-btn-arrow">
        <i className="fa fa-arrow-right arrow-intro"> </i>
      </div>
    </div>
  );
}
