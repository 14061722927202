// @flow
import * as React from "react";
import $ from "jquery";
import "./SimpleDropdown.scss";
import { css, cx } from "emotion";

export function SimpleDropdown({
  selected,
  customCssStyle = "",
  options,
  selectOption,
  emptyLabel,
  showError,
  withArrow,
}) {
  const [state, setState] = React.useState({});
  const [id, setId] = React.useState(Date.now() + "Date_picker-");
  const selectRef = React.useRef();

  function toggleOpen() {
    setState({ ...state, open: !state.open });
  }
  function setOpen(open) {
    setState({ ...state, open });
  }

  function listenClickOutside(event) {
    let target = event.target;
    if (target !== selectRef) {
      const clickedOutside = $(target).closest("#" + id).length === 0;
      if (clickedOutside) {
        setOpen(false);
      }
    }
  }
  React.useEffect(() => {
    return () => {
      window.removeEventListener("click", listenClickOutside);
    };
  }, []);
  React.useEffect(
    () => {
      if (state.open) {
        window.addEventListener("click", listenClickOutside);
      } else {
        if (state.open === false) {
          window.removeEventListener("click", listenClickOutside);
        }
      }
    },
    [state.open]
  );
  return (
    <div
      id={id}
      className={
        customCssStyle
          ? cx(
              "simple-dropdown-container",
              css`
                ${customCssStyle}
              `
            )
          : "simple-dropdown-container"
      }
    >
      <div
        onClick={toggleOpen}
        className={`option-select-label-selected${state.open ? ' option-selected-dropdown-open' : ""}`}
        style={
          state.open
            ? { borderColor: "transparent" }
            : showError && !selected
            ? { borderColor: "red" }
            : {}
        }
      >
        <div>{selected ? selected.label : "-"}</div>
        {withArrow && (
          <div className="c_r_dropdown_selected_item_arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.17 9.27">
              <defs />
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <polygon points="2.15 0 0 2.06 7.08 9.27 14.17 2.06 12.04 0 7.08 5.02 2.15 0" />
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>

      {state.open && (
        <div className={"abs-dropdown-parent"}>
          <div className="relative-dropdown-items">
            <div className="simple-dropdown-option-container">
              {options.map((option) => (
                <div
                  className={
                    "simple-dropdown-option" +
                    (selected && selected.value === option.value
                      ? " option-selected"
                      : "")
                  }
                  key={option.key}
                  onClick={() => {
                    setOpen(false);
                    selectOption(option);
                  }}
                >
                  {" "}
                  {option.label}
                </div>
              ))}
              {options.length === 0 && (
                <div
                  className={"simple-dropdown-option"}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  {emptyLabel || "Nicio opțiune."}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
