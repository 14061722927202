import "./MenuSelect.scss";
import React from "react";
import objectPath from "object-path";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function MenuSelect({
  selectedOption,
  value,
  options,
  onChange,
  labelPath,
  defaultLabel,
  error
}) {
  return (
    <div className={`select-btn-container-item${error ? " error_select" : ""}`}>
      <div className="select-label-container">
        {selectedOption ? objectPath.get(selectedOption, labelPath) : defaultLabel}
      </div>
      <div className={`select-arrow-down`}>
          <FontAwesomeIcon icon={faChevronDown} color="black" className="arrow-select" />
      </div>
      <select
        className="select-btn-container-item"
        onChange={onChange}
        value={value}
      >
        {options.map((item, index) => (
          <option
            key={`select-btn-option-value-${objectPath.get(
              selectedOption,
              labelPath
            )}-${index}`}
            value={String(index)}
            className="poppins"
          >
            {objectPath.get(item, labelPath)}
          </option>
        ))}
      </select>
    </div>
  );
}
