import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LayoutInitializer from "./LayoutInitializer";
import LayoutLoader from "./../Loader/Loader";
import Footer from "./Footer";
import objectPath from "object-path";
import { css } from "emotion";

function Layout({ children, location, history }) {
  const {
    menu_color_body_bg
  } = useSelector(({builder}) => ({
    menu_color_body_bg: objectPath.get(builder, "preferences.menu_color_body_bg")
  }));
  const [windowSize, setWindowSize] = React.useState({height: window.innerHeight, width: window.innerWidth});
  const menu_color_body_bg_LINE = `${
    menu_color_body_bg
      ? `background-color: ${menu_color_body_bg}!important;`
      : ""
  }`;
  function on_resize_window(e) {
    if(windowSize.height < window.innerHeight || windowSize.width < window.innerWidth ) {
      setWindowSize({height: window.innerHeight, width: window.innerWidth})
    }
  };


  React.useEffect(() => {
    window.addEventListener("resize", on_resize_window);
    return () => {
      window.removeEventListener("resize", on_resize_window);
    }
  }, [])
  const menuBodyCustomStyle = css`
  ${menu_color_body_bg_LINE}
`;

// height: ${windowSize.height}px;
// width: ${windowSize.width}px;
// console.log(`
// ${menu_color_body_bg_LINE}
// height: ${windowSize.height}px;
// width: ${windowSize.width}px;
// `);
  return (
    <LayoutInitializer location={location} history={history}>
      <LayoutLoader />
      {location.pathname === "/" ? (
        <React.Fragment> {children} </React.Fragment>
      ) : (
        <div id="menu-layout-container" className={menuBodyCustomStyle}>
          {children}
          <Footer />
        </div>
      )}
    </LayoutInitializer>
  );
}

const mapStateToProps = ({ builder }) => ({});
export default withRouter(connect(mapStateToProps)(Layout));
