import objectPath from "object-path";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const actionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  CLOSE_AUTO_LOGOUT_POPUP: "CLOSE_AUTO_LOGOUT_POPUP",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  SET_TOKEN_FIREBASE: "SET_TOKEN_FIREBASE"
};
const initialAuthState = {
  user: undefined
};
export const selectors = {
  getAuthInfo: (store, path) => {
    return objectPath.get(store, path);
  }
};
export const reducer = 
persistReducer({storage: storage, key: "auth_spending_app1", blacklist: ["autoLogoutPopup"] }, 
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.LOGIN: {
        return { ...state, user: action.payload };
      }
      case actionTypes.LOGOUT: {
        return {...initialAuthState, autoLogoutPopup: action.payload.autoLogoutPopup};
      }
      case actionTypes.CLOSE_AUTO_LOGOUT_POPUP: {
        return {...state, autoLogoutPopup: null};
      }
      case actionTypes.UPDATE_USER_INFO: {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload
          }
        }
      }
      case actionTypes.SET_TOKEN_FIREBASE: {
        return {
          ...state,
          user: {
            ...state.user,
            token_firebase: action.payload.token_firebase
          }
        }
      }
      default:
        return state;
    }
  }
);
export const actions = {
  login: payload => {
    localStorage.setItem("token_firebase", payload.token_firebase);
    return ({ type: actionTypes.LOGIN, payload })
  },
  logout: payload => {
    return({ type: actionTypes.LOGOUT, payload: payload || {} })
  },
  set_token_firebase: (payload) => {
    return ({ type: actionTypes.SET_TOKEN_FIREBASE, payload });
  },
  closeAutoLogoutPopup: () => ({ type: actionTypes.CLOSE_AUTO_LOGOUT_POPUP }),
  updateUserInfo: (payload) => {
    return({ type: actionTypes.UPDATE_USER_INFO, payload });
  }
};


