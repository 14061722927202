import React, { useEffect } from "react";

import "./PromoCode.scss";

import CloseSvg from "reusableComponents/Svgs/CloseSvg";

import $ from "jquery";
import Button from "reusableComponents/Button/Button";

export default function PromoCode({
  general_color,
  product_separator_color,
  text_color,
  inner_text_color,
  theme_text_style,
  is_mobile,
  deliveryStep = 1,

  onChangePromo,
  promoValue,
  submitPromoCode,
  deletePromoCode,
  validCode = "",
  priceReduction = 0,
  isFetching
}) {
  const input_ref = React.useRef();

  function alterModalHeight() {
    const nodeOnFocusMobile = document.getElementById("receipt_command__");
    if (!is_mobile || !nodeOnFocusMobile) return;
    const viewportOffset = input_ref.current.getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    const top = viewportOffset.top;
    setTimeout(() => {
      $(nodeOnFocusMobile).css("height", window.innerHeight + "px");
      $(nodeOnFocusMobile).animate({ scrollTop: top }, 100, () => {});
    }, 500);
  }
  function redoModalHeight() {
    const nodeOnFocusMobile = document.getElementById("receipt_command__");
    if (!is_mobile || !nodeOnFocusMobile) return;

    $(nodeOnFocusMobile).css("height", "");
  }
  function onSubmitPromoCode() {
    if(!promoValue) {
      return;
    }
    if(input_ref.current) {
      input_ref.current.blur();
    }
    submitPromoCode();
  }
  return deliveryStep === 2 && !priceReduction ? "" : (
    <React.Fragment>
      { validCode ? (
        <div className="command_receipt_total_line promo-left-container">
          <div>
            <div style={{ color: general_color }}>{validCode}</div>
            <div onClick={deletePromoCode} style={{visibility: deliveryStep === 2 ? "hidden" : "visible"}} className="promo-svg-delete">
              <CloseSvg color={general_color} />
            </div>
          </div>
          <div style={{ color: general_color }}>-{priceReduction === 1 ? "1 leu" : priceReduction + " lei"}</div>
        </div>
      ) : (
        <div className="promo-container">
          <div className="promo-input-container">
            <input
              onClick={(event) => {
                alterModalHeight();
              }}
              onBlur={redoModalHeight}
              type={"text"}
              ref={input_ref}
              style={{
                backgroundColor: product_separator_color,
                color: text_color,
                borderColor: product_separator_color,
              }}
              disabled={isFetching}
              onWheel={(event) => {
                event.target.blur();
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  onSubmitPromoCode();
                }
              }}
              onChange={onChangePromo}
              value={promoValue}
              placeholder={"Cod promoţional"}
            />
          </div>
          <Button
            backgroundColor={"transparent"}
            text="Aplică"
            borderColor={general_color}
            color={general_color}
            onClick={() => {
              onSubmitPromoCode();
            }}
            disabled={isFetching}
            containerClassName="promo-submit-button"
          />
        </div>
      )}
    </React.Fragment>
  );
}
