export const errors_template_form = {
  address_invalid: "Adresă invalidă",
  email_invalid: "Adresă de e-mail invalidă",
  name_invalid: "Nume invalid",
  phone_invalid: "Număr invalid",
  delivery_type_invalid: "Nu ati selectat tipul livrarii",

  name: "Va rugăm să introduceți numele Dvs.",
  email_empty: "Va rugăm să introduceți e-mail-ul Dvs.",
  adress_empty: "Va rugăm să introduceți adresa Dvs.",
};
export const delivery_options = [
  {
    id: 1,
    text: "Livrare la domiciliu",
  },
  {
    id: 3,
    text: "Livrare NON-CONTACT la domiciliu",
  },
  {
    id: 2,
    text: "Ridicare personală din restaurant",
  },
];
export const month_names = [
  "ianuarie",
  "februarie",
  "martie",
  "aprilie",
  "mai",
  "iunie",
  "iulie",
  "august",
  "septembrie",
  "octombrie",
  "noiembrie",
  "decembrie",
];
export const week_days_names = [
  {
    short: "lu",
  },
  {
    short: "ma",
  },
  {
    short: "mi",
  },
  {
    short: "jo",
  },
  {
    short: "vi",
  },
  {
    short: "sâ",
  },
  {
    short: "du",
  },
];
export const shipping_form_inputs = [
  {
    form_prop: "address",
    placeholder: "Adresa ta*",
    type: "text",
  },
  {
    form_prop: "name",
    placeholder: "Numele tău*",
    type: "text",
  },
  {
    form_prop: "email",
    placeholder: "E-mailul tău*",
    type: "text",
  },
  {
    form_prop: "phone",
    placeholder: "Telefonul tău*",
    type: "number",
  },
  {
    form_prop: "message",
    placeholder: "Mesaj suplimentar",
    type: "text",
  },
];
export const lazy_form_init = {
  email: "",
  name: "",
  address: "",
  phone: "",
  id_county: "",
  message: "",
  delivery_type: "",
  receive_emails: false,
  delivery_asap: true,
  id_venue_location: null,
  hour: null,
  date: null,
  payment_type: "online",
  errors: {},
};
export const lazy_receipt_dropdown_init = JSON.stringify({
  open: false,
  position: {
    top: 0,
    left: 0,
  },
  items: [],
  index_selected: 0,
  onSelectCallback: () => {
    console.log("Empty callback dropdown select function");
  },
  parent_el_classname: null,
  containerClassName: null,
  additionalStyleContainer: null,
  additionalStyleInnerCont: null,
});
//db has day indexing from "0"-monday... and date().getDay() 0 = sunday, 1 = monday
export const db_to_datejs_ind = {
  "0": 1, //monday
  "1": 2, //tue
  "2": 3, //wed
  "3": 4, //thurs
  "4": 5, //fri
  "5": 6, //satur
  "6": 0, //sun
};
export const try_hours_delivery = [
  "00:00:00",
  "00:30:00",

  "01:00:00",
  "01:30:00",

  "02:00:00",
  "02:30:00",

  "03:00:00",
  "03:30:00",

  "04:00:00",
  "04:30:00",

  "05:00:00",
  "05:30:00",

  "06:00:00",
  "06:30:00",

  "07:00:00",
  "07:30:00",

  "08:00:00",
  "08:30:00",

  "09:00:00",
  "09:30:00",

  "10:00:00",
  "10:30:00",

  "11:00:00",
  "11:30:00",

  "12:00:00",
  "12:30:00",

  "13:00:00",
  "13:30:00",

  "14:00:00",
  "14:30:00",

  "15:00:00",
  "15:30:00",

  "16:00:00",
  "16:30:00",

  "17:00:00",
  "17:30:00",

  "18:00:00",
  "18:30:00",

  "19:00:00",
  "19:30:00",

  "20:00:00",
  "20:30:00",

  "21:00:00",
  "21:30:00",

  "22:00:00",
  "22:30:00",

  "23:00:00",
  "23:30:00",
];
export const try_hours_quarter = [
  "00:00:00",
  "00:15:00",
  "00:30:00",
  "00:45:00",

  "01:00:00",
  "01:15:00",
  "01:30:00",
  "01:45:00",

  "02:00:00",
  "02:15:00",
  "02:30:00",
  "02:45:00",

  "03:00:00",
  "03:15:00",
  "03:30:00",
  "03:45:00",

  "04:00:00",
  "04:15:00",
  "04:30:00",
  "04:45:00",

  "05:00:00",
  "05:15:00",
  "05:30:00",
  "05:45:00",

  "06:00:00",
  "06:15:00",
  "06:30:00",
  "06:45:00",

  "07:00:00",
  "07:15:00",
  "07:30:00",
  "07:45:00",

  "08:00:00",
  "08:15:00",
  "08:30:00",
  "08:45:00",

  "09:00:00",
  "09:15:00",
  "09:30:00",
  "09:45:00",

  "10:00:00",
  "10:15:00",
  "10:30:00",
  "10:45:00",

  "11:00:00",
  "11:15:00",
  "11:30:00",
  "11:45:00",

  "12:00:00",
  "12:15:00",
  "12:30:00",
  "12:45:00",

  "13:00:00",
  "13:15:00",
  "13:30:00",
  "13:45:00",

  "14:00:00",
  "14:15:00",
  "14:30:00",
  "14:45:00",

  "15:00:00",
  "15:15:00",
  "15:30:00",
  "15:45:00",

  "16:00:00",
  "16:15:00",
  "16:30:00",
  "16:45:00",

  "17:00:00",
  "17:15:00",
  "17:30:00",
  "17:45:00",

  "18:00:00",
  "18:15:00",
  "18:30:00",
  "18:45:00",

  "19:00:00",
  "19:15:00",
  "19:30:00",
  "19:45:00",

  "20:00:00",
  "20:15:00",
  "20:30:00",
  "20:45:00",

  "21:00:00",
  "21:15:00",
  "21:30:00",
  "21:45:00",

  "22:00:00",
  "22:15:00",
  "22:30:00",
  "22:45:00",

  "23:00:00",
  "23:15:00",
  "23:30:00",
  "23:45:00",
  "23:59:59"
];
export const delivery_type_names = {
  1: "delivery",
  2: "pickup",
  3: "delivery non contact",
};
export const DELIVERY_TYPES = {
  delivery: 1,
  pickup: 2,
  delivery_non_contact: 3
};
export const delivery_ids_from_name = {
  delivery: 1,
  pickup: 2,
  "delivery non contact": 3,
};
export const UNIQUE_SLUGS = {
  timeless: "timeless",
  burgervan: "burgervan",
  krud: "krud",
  andalu: "andalu",
  oddity: "oddity",
  softandgrace: "softandgrace",
  cuptorulmoldovencei: "shop.cuptorulmoldovencei.ro",
  hanuldacilor: "shop.hanuldacilor.ro",
};

export const paymentMethods = ["online", "cash", "card"];
export const paymentTypeNames = {
  online: { defualt: "Plată online" },
  cash: { defualt: "Cash la livrare", 2: "Cash la ridicare" },
  card: { defualt: "Card la livrare", 2: "Card la ridicare" },
};

export const venes_option_pop_const = {
  "18": {
    0: {
      message:
        "Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    },
    1: {
      message:
        " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    },
    2: {
      message:
        " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    },
    3: {
      message:
        " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    },
    4: {
      message:
        " În zilele de vineri și sâmbătă facem livrări, așa că te așteptăm să comanzi în avans.",
    },
    5: {
      message:
        " În zilele de vineri și sâmbătă facem livrări, așa că te așteptăm să comanzi în avans.",
    },
    6: {
      message:
        "Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    },
  },
};

export const defaultTermsAndConditonLink =
  "https://www.poloniq.ro/Termeni_si_conditii_Poloniq.pdf";
export const defaultConfTermsLink =
  "https://www.poloniq.ro/Politica_confidentialitate_Poloniq.pdf";
export const defaultCookiesTermsLink =
  "https://www.poloniq.ro/Politica_de_cookies_Poloniq.pdf";

export const sectionsOrdered = [
  "first_screen_section",
  "command_section",
  "reservation_section",
  "gallery_section",
  "contact_section",
  "footer_section",
];

/**  
 * @typedef {Object} IDeliveryProgram
 * @property {String} closed
 * @property {Null} closed_pickup
 * @property {String} day_of_the_week
 * @property {String} id_county
 * @property {String} id_delivery_program
 * @property {String} id_venue
 * @property {String} program_end
 * @property {Null} program_end_pickup
 * @property {String} program_end_real
 * @property {String} program_start
 * @property {Null} program_start_pickup
 * @property {String} program_start_real
 */


/** 
 * @typedef {Object} IStateHome
 * @property {Object} FINAL_RULE
 * @property {Boolean} always_show_location
 * @property {String} background_color
 * @property {String} bank_account
 * @property {Array} blocked_program
 * @property {String} cartDatepickerCss
 * @property {Object} center
 * @property {String} client_email
 * @property {String} client_mesaj
 * @property {String} client_nume
 * @property {String} client_persoane
 * @property {String} client_telefon
 * @property {Object} command_cart_products
 * @property {Array} command_categories
 * @property {Object} command_closed_content_popup
 * @property {Boolean} command_section_is_higher
 * @property {String} company_address
 * @property {String} company_name
 * @property {String} confTermsLink
 * @property {String} cookiesTermsLink
 * @property {String} cui
 * @property {String} currentMinDeliveryDate
 * @property {Array} date_blocate_array
 * @property {String} date_string_reservation
 * @property {Array} day_closed
 * @property {Number} default_delivery
 * @property {String} default_product_image
 * @property {Boolean} deliver
 * @property {Boolean} delivery_asap_only
 * @property {Number} delivery_price
 * @property {Array} delivery_price_array
 * @property {Array} delivery_price_array_intervals
 * @property {Boolean} disable_asap
 * @property {Boolean} disable_asap_from_state
 * @property {Boolean} disable_delivery
 * @property {Boolean} disable_delivery_hour
 * @property {Boolean} disable_delivery_option
 * @property {Boolean} disable_delivery_with_delivery
 * @property {Boolean} disable_gallery_section
 * @property {Boolean} disable_menu_link
 * @property {Boolean} disable_non_contact
 * @property {Boolean} disable_online_payment
 * @property {Boolean} disable_order_section
 * @property {Boolean} disable_pickup
 * @property {Boolean} disable_program_popup
 * @property {Boolean} disable_reservations
 * @property {Boolean} disable_venue_program
 * @property {String} disabled_color
 * @property {Boolean} enable_card_payment
 * @property {Boolean} enable_cash_payment
 * @property {Boolean} enable_promocode
 * @property {Boolean} enable_tips
 * @property {Boolean} eroare
 * @property {Boolean} exclude_shipping_for_min_order
 * @property {String} fb_page_url
 * @property {String} first_screen_background_image
 * @property {String} first_screen_logo
 * @property {String} first_screen_logo_className
 * @property {String} first_screen_logo_width_desktop
 * @property {String} first_screen_title
 * @property {Boolean} forbiden_interval
 * @property {Boolean} forbiden_interval_allow_btns
 * @property {Number} free_delivery_from
 * @property {String} general_color
 * @property {String} header_call_to_action
 * @property {Boolean} hide_cart_optional_checkbox
 * @property {String} hourSelectCss
 * @property {String} hour_reservation
 * @property {String} hour_reservation_stay
 * @property {String} id_venue
 * @property {String} ig_page_url
 * @property {Array} initial_popup_text
 * @property {String} inner_text_color
 * @property {Object} isFetchingScrollAnim
 * @property {Boolean} is_mobile
 * @property {String} last_screen_address
 * @property {String} last_screen_email
 * @property {String} last_screen_latitude
 * @property {String} last_screen_longitude
 * @property {String} last_screen_phone
 * @property {Array} markers
 * @property {String} menu_item_text
 * @property {String} minDeliveryDate
 * @property {Null} minDeliveryDateBasedOnCart
 * @property {Number} min_order
 * @property {Number} min_order_initial
 * @property {Array} modals
 * @property {String} name_venue
 * @property {Object} open_loader
 * @property {Array} ore_blocate_array
 * @property {String} pin
 * @property {String} powerByPhoto
 * @property {Number} preferenceUpdate
 * @property {Object} productDeliveryRules
 * @property {String} productItemCss
 * @property {Array} product_items
 * @property {String} product_separator_color
 * @property {Boolean} products_have_image
 * @property {Array} program
 * @property {Arrray.<IDeliveryProgram>} program_delivery
 * @property {Array} program_delivery_rules
 * @property {Array} program_delivery_string
 * @property {Array} program_list_elements
 * @property {Array} program_venue
 * @property {String} promoValue
 * @property {Object} receipt_dropdown
 * @property {String} refresh_opening_callback
 * @property {String} refresh_opening_callback_target
 * @property {String} reg_com
 * @property {String} reservationDatepickerCss
 * @property {String} reservationFormularCss
 * @property {String} reservation_max_people
 * @property {String} reservation_promotion_message_from
 * @property {String} second_screen_menu_link
 * @property {String} second_screen_subtitle
 * @property {String} second_screen_title
 * @property {Array} sectionsOrdered
 * @property {Number} sel_command_cat_index
 * @property {Object} settings
 * @property {Boolean} shippingFromShowErrors
 * @property {Object} shipping_form
 * @property {Array} shipping_hour_items
 * @property {Boolean} showErrors
 * @property {String} sizesSelectCss
 * @property {Array} sliderImages
 * @property {Boolean} some_products_have_image
 * @property {Boolean} sticky_command_navbar
 * @property {String} termsAndConditonLink
 * @property {String} text_color
 * @property {String} third_screen_right_image
 * @property {Object} tipValue
 * @property {Boolean} trimitere_ok
 * @property {String} unique_key
 * @property {Boolean} use_cart_required_terms_checkbox
 * @property {Boolean} use_reservation_stay_form
 * @property {Array} valid_hours_reservation
 * @property {Array} valid_hours_reservation_stay
 * @property {Boolean} venue_active
 * @property {Array} venue_counties
 * @property {Object} venue_county_names
 * @property {Object} venue_locations
 * @property {String} white_theme
 * @property {Number} zoom
 */
