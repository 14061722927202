// @flow
import * as React from "react";
import "./ProceedPopup.scss";
import { Emoticon } from "../../Emoticon/Emoticon";
import PopupFrame from "../Frame";
import Button from "../../Button/Button";

export default function ProceedPopup({
  general_color,
  frameProps,
  callbackNo,
  callbackYes,
  content,
  showOnlyOneOption,
}) {
  return (
    <PopupFrame {...frameProps}>
      <div className="proceed-popup-container">
        <div
          style={{ color: general_color }}
          className="optional_mesasge_popup"
        >
          {content || "Doriți să efectuați acțiunea?"}
        </div>
        {showOnlyOneOption ? (
          <div className="proceed-popup-button-container">
            <Button
              color={general_color}
              borderColor={general_color}
              text={"Ok"}
              onClick={callbackYes}
            />
          </div>
        ) : (
          <div className="proceed-popup-button-container">
            <Button
              color={general_color}
              borderColor={general_color}
              text={"Da"}
              onClick={callbackYes}
            />
            <Button
              color={general_color}
              borderColor={general_color}
              text={"Nu"}
              onClick={callbackNo}
            />
          </div>
        )}
      </div>
    </PopupFrame>
  );
}
