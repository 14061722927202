// Modal.js
import React from "react";
import { createPortal } from "react-dom";
// We get hold of the div with the id modal that we have created in index.html
import "./PopupItemContainer.scss";
import { css, cx } from "emotion";

const modalRoot = document.getElementById("body-modal-portal");

class PopupItemContainer extends React.Component {
  constructor(props) {
    super(props);
    // We create an element div for this modal
    this.element = document.createElement("div");
  }
  // We append the created div to the div#modal
  componentDidMount() {
    modalRoot.appendChild(this.element);
  }
  /**
   * We remove the created div when this Modal Component is unmounted
   * Used to clean up the memory to avoid memory leak
   */
  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }
  render() {
    return createPortal(
      <div
        className={
          this.props.customCssStyle
            ? cx(
                "popup-container-item-portal",
                css`
                  ${this.props.customCssStyle}
                `
              )
            : "popup-container-item-portal"
        }
      >
        {this.props.children}
      </div>,

      this.element
    );
  }
}
export default PopupItemContainer;
