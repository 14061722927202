// @flow
import * as React from 'react';
import "./PromoIcon.css";

export default function PromoIcon({containerClassName, text, backgroundColor, color}) {
  return (
    <span
    style={{
        ...(backgroundColor ? {backgroundColor} : {}),
        ...(color ? {color} : {})
    }}
    className={`_box_sizing_border_box_ product_promotion_icon_cont${containerClassName? " "+containerClassName : ""}`}
    >
        {`${text || "-%"}`}
    </span>
  );
};