// @flow
import * as React from "react";
import "./Checkbox.scss";
import $ from "jquery";

function LabelInput({
  onChange,
  checked,
  value,
  className,
  placeholder,
  style,
  type,
  is_mobile,
  nodeOnFocusMobile,
  focusKeyInput,
}) {
  const input_ref = React.useRef();
  React.useEffect(
    () => {
      if (focusKeyInput > -1 && Date.now() - focusKeyInput < 1000) {
        if (input_ref.current) {
          input_ref.current.focus();
          alterModalHeight();
        }
      }
    },
    [focusKeyInput]
  );
  function redoModalHeight() {
    if (!is_mobile || !nodeOnFocusMobile) return;

    $(nodeOnFocusMobile).css("height", "");
  }
  function alterModalHeight() {
    if (!is_mobile || !nodeOnFocusMobile) return;
    const viewportOffset = input_ref.current.getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    const top = viewportOffset.top;
    setTimeout(() => {
      $(nodeOnFocusMobile).css("height", window.innerHeight + "px");
      $(nodeOnFocusMobile).animate({ scrollTop: top }, 100, () => {});
    }, 500);
  }
  return (
    <input
      onClick={(event) => {
        alterModalHeight();
      }}
      onBlur={redoModalHeight}
      type={type || "text"}
      ref={input_ref}
      style={{
        borderBottom: `3px solid ${(style || {}).borderColor}`,
        ...(style ? style : {}),
      }}
      onWheel={(event) => {
        if (type === "number") {
          event.target.blur();
        }
      }}
      className={className}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
}

export function Checkbox({
  checked,
  onClick,
  label,
  containerClassName,
  labelClassName,
  squareClassName,
  checkedClassName,
  borderColor,
  color,
  svgColor,
  label_style,
  boxBackgroundColor,
  disabled,
  labelInput,
  labelRight,
  onClickSquareOnly,
}) {
  const [focusKeyInput, setFocusKeyInput] = React.useState(-1);
  return (
    <div
      onClick={
        disabled
          ? () => {}
          : (event) => {
              
              if (event.target.tagName === "A") {
                return;
              }
              if (labelInput) {
                setFocusKeyInput(Date.now());
              }
              onClick(event);
            }
      }
      className={`check_box_container__${
        containerClassName ? " " + containerClassName : ""
      }${labelInput ? " checkbox-with-input" : ""}`}
      style={
        color
          ? {
              color,
            }
          : {}
      }
    >
      <div
        className={`check_box_square__${
          squareClassName ? " " + squareClassName : ""
        }${checked ? " check_box_square_checked__" : ""}${
          checkedClassName ? " " + checkedClassName : ""
        }${disabled ? " checkbox_disabled" : ""}`}
        style={{
          ...(borderColor
            ? {
                borderColor,
              }
            : {}),
          ...(checked
            ? {
                backgroundColor: borderColor,
              }
            : {}),
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={
            svgColor
              ? {
                  fill: svgColor,
                }
              : {}
          }
          viewBox="0 0 13.97 10.93"
        >
          <defs />
          <polyline points="11.63 0.68 11.63 0.68 5.86 6.83 2.42 3.43 0.72 5.09 6.21 10.21 13.26 2.03 11.63 0.68" />
        </svg>
      </div>
      <div
        className={`check_box_label__${
          labelClassName ? " " + labelClassName : ""
        }${disabled ? " checkbox_disabled" : ""}${
          labelInput ? " check_box_input_container" : ""
        }`}
        style={{
          ...(label_style ? label_style : {}),
        }}
      >
        {labelInput ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <div> {label} </div>{" "}
            <LabelInput
              focusKeyInput={focusKeyInput}
              checked={checked}
              {...labelInput}
            />{" "}
            <div> {labelRight} </div>
          </div>
        ) : (
          <React.Fragment>{label} </React.Fragment>
        )}
      </div>
    </div>
  );
}
export default Checkbox;
