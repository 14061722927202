// @flow
import React, {Component} from "react";
import "./Loader.scss";
import { connect } from "react-redux";
import $ from "jquery";

import logo_simbol from "assets/img/logo_simbol.png";

import * as builder from "store/reducers/builderReducer";
import { cx, css } from "emotion";

class LayoutLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    }
  }
  timer = 0;
  componentDidMount = () => {
    this.updateTimer();
  }
  componentDidUpdate = (prevProps) => {
    if(prevProps.layoutLoader.key !== this.props.layoutLoader.key) {
      this.updateTimer();
    }
  }
  updateTimer = () => {
    const {open, duration} = this.props.layoutLoader;
    clearTimeout(this.timer);
    if(open) {
      this.setState({show: true});
    } else {
      if(duration && duration === 1) {
        this.setState({show: false});
      } else {
        this.timer = setTimeout(() => {
          this.setState({show: false});
        }, duration);
      }
    }
  }
  render() {
    return !this.state.show ? "" : (
      <div id="layout_loader_site_id" className={cx(`layout-loader-container${
          this.props.layoutLoader.open
            ? Number(this.props.layoutLoader.duration) === 1 ? "" : " menu-page-fade-in-anim-class-loader"
            : " menu-page-fade-out-anim-class-loader"
        }`,
        css`
          animation-duration: ${(this.props.layoutLoader.duration) / 1000}s;
        `
      )}>
        <div className="flex_div">
          <img src={logo_simbol} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ builder }) => ({
  layoutLoader: builder.layoutLoader,
});
export default connect(mapStateToProps)(LayoutLoader);
