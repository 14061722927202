import React from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from "prop-types";

import AppendBodyComponent from '../AppendBodyComponent'
import "./Popup.css";

class Modal extends AppendBodyComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: 0
    }
    this.uniqueId = uuid();
    this.setAppendElementId(this.uniqueId);
  }
  componentDidMount() {
    window.addEventListener("resize", this.on_resize_window);
    this.setState({height: window.innerHeight});
    this.updateSelf();
  }
  componentDidUpdate() {
    this.updateSelf();
  }
  on_resize_window = () => {
    // this.setState({height: window.innerHeight})
    // this.updateSelf();
  }
  componentWillUnmount() {
    this.removeAppendElement();
  }
  updateSelf() {
    this.updateAppendElement(
      <div
        key={this.uniqueId}
        style={{...(this.state.height ? {height: this.state.height} : {})}}
        className={`___modal${this.props.className ? " "+ this.props.className : ""}`}
      >
          {this.props.children}
      </div>
    );
  }
  render() {
    // NOTE: since this is an append body component, we need to manage the rendering ourselves
    return null;
  }
}
Modal.propTypes = {
  className: PropTypes.string,
};
Modal.defaultProps = {
  className: null,
};
export default Modal;