import { actions } from "./../reducers/builderReducer";
import axios from "axios";

export function loaderActions(dispatch) {
  return {
    openLayoutLoader: (layoutLoader) => {
      const { open, duration } = layoutLoader || { open: true, duration: 300 };
      dispatch(
        actions.setLayoutLoader({ layoutLoader: { open, duration } })
      );
    },
    closeLayoutLoader: (layoutLoader) => {
        const { open, duration } = layoutLoader || { open: false, duration: 300 };
        dispatch(
          actions.setLayoutLoader({ layoutLoader: { open, duration } })
        );
    }
  };
}
