// @flow
import React, {Component} from "react";
import "./Loader.scss";
import { connect } from "react-redux";
import $ from "jquery";

import logo_simbol from "assets/img/logo_simbol.png";

import * as builder from "store/reducers/builderReducer";

export default function LayoutLoaderFixed(){
    return (
      <div id="layout_loader_site_fixed_id" className="layout-loader-container">
        <div className="flex_div">
          <img src={logo_simbol} />
        </div>
      </div>
    );
}
