import React, { useRef } from "react";

export default function MenuSearchProduct({value, onChange, onFocus, onBlur, id_venue, placeholder}) {
  const inputRef = useRef();
  
  return (
    <div className="form search-form poppins">
      <input
        ref={inputRef}
        className="poppins"
        type="text"
        placeholder={placeholder || "Caută produs"}
        name={"produse_"+id_venue}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button onClick={() => {
        if(inputRef.current) {
          inputRef.current.focus();
        }
      }} type="submit" className="search-button">
        <i className="fa fa-search" />
      </button>
    </div>
  );
}
