import React, { Component } from "react";
import Routes from "./components/Routes";
import Loader from "components/Loader/Loader";
import axios from "axios";

import "./App.css";
import "./globals.scss";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { actions } from "store/reducers/builderReducer";
import LayoutLoaderFixed from "components/Loader/LoaderFixed";

class App extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.checkVersion();
  };
  checkVersion = () => {

    window.version = require("./../package.json").version;
    console.log("package_version is " + window.version);
    axios
      .get(`https://api.poloniq.ro/version?unique_parameter=${Date.now()}`)
      .then((response) => {
        if (response.data[0].version_name) {
          const current_version = response.data[0].version_name;
          if (current_version !== window.version) {
            caches.keys().then((keyList) => {
              return Promise.all(
                keyList.map((key) => {
                  return caches.delete(key);
                })
              );
            });
            this.props.setVersionChecked({
              versionChecked: true,
              version: current_version
            });
            window.location.reload(true);
          } else {
            this.props.setVersionChecked({
              versionChecked: true,
              version: window.version,
            });
          }
        }

      })
      .catch((err) => {
        this.props.setVersionChecked({
          versionChecked: true,
          version: window.version,
        });

        console.log("version call error", err);
      });
  };
  render() {
    return !this.props.versionChecked ? (
      <LayoutLoaderFixed />
    ) : (
      <div id="google_translate_element2">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setLayoutLoader: (payload) => dispatch(actions.setLayoutLoader(payload)),
  setVersionChecked: (payload) => dispatch(actions.setVersionChecked(payload)),
});
const mapStateToProps = ({ builder }) => ({
  version: builder.version,
  versionChecked: builder.versionChecked,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
