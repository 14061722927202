// @flow
import * as React from "react";
import "./Button.css";

export function Button({
  color,
  backgroundColor,
  borderColor,
  containerClassName,
  disabledClassName,
  onClick,
  text,
  disabled,
  variant,
  no_animation,
  id,
  disabled_style = {},
  style = {}
}) {
  return (
      <div
        {...(id? {id} : {}) }
        style={{
          ...style,
          ...(backgroundColor ? { backgroundColor } : {}),
          ...(borderColor ? { borderColor } : {}),
          ...(disabled ? disabled_style : {})
        }}
        className={`generic_button_container${
          containerClassName ? " " + containerClassName : ""
        }${disabled ? ` disabled___${
          disabledClassName ? " " + disabledClassName : ""
        }` : ""}${variant ? " " + variant : ""}${
            no_animation || disabled ? "" : ` gen_btn_anim`
        }`}
        onClick={disabled ? ()=> {} : onClick}
      >
        <span
          style={{
            ...(color ? { color } : {}),
            ...(disabled ? disabled_style : {})
          }}
        >
          {text}
        </span>
      </div>
  );
}
export default Button;
